import React from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import ErrorBoundary from '../components/ErrorBoundary';
import browserHistory from '../utils/browser_history';
import root from '../components/root';
import 'react-calendar/dist/Calendar.css';
import '../styles/global.scss';

const Root = ({ store }) => (
  <Provider store={store}>
    <ConnectedRouter history={browserHistory}>
      <ErrorBoundary>
        <Route path="/" component={root} />
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
