import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers';
import browserHistory from '../utils/browser_history';

const configureStore = (preloadedState) => {
  if (process.env.NODE_ENV === 'development') {
    return createStore(
      createRootReducer(browserHistory),
      preloadedState,
      compose(
        applyMiddleware(thunk, routerMiddleware(browserHistory), createLogger()),
      ),
    );
  }
  return createStore(
    createRootReducer(browserHistory),
    preloadedState,
    compose(applyMiddleware(thunk, routerMiddleware(browserHistory))),
  );
};

export default configureStore;
