import React from 'react';
import PropTypes from 'prop-types';
import { MdPlayCircleOutline } from 'react-icons/md';
import { FaCreativeCommonsShare } from 'react-icons/fa';
import classNames from 'classnames';
import { FiPlay } from 'react-icons/fi';
import { getMatchAction } from '../../../helpers/matchFacts';
import s from './style.module.scss';

const Fact = ({
  displayVideo,
  displayImage,
  action,
  match,
}) => {
  const types = (type) => {
    switch (type.hierarchy) {
      case 'low':
        return s.container__low;
      case 'medium':
        return s.container__medium;
      default:
        return s.container__high;
    }
  };

  return (
    <div className={s.container}>
      <div className={s.container__time}>{`${action.actionTime}'`}</div>
      <div className={classNames(
        s.container__svg,
        types(getMatchAction(action.name, match.dataSourceName)),
      )}
      >
        {action.name !== null
          ? getMatchAction(action.name, match.dataSourceName).icon
          : <FiPlay />}
      </div>
      <div className={s.container__action}>
        <div className={s.container__action__fact}>
          {getMatchAction(action.name, match.dataSourceName).name
            ? getMatchAction(action.name, match.dataSourceName).name
            : action.name}
        </div>
        <div className={s.container__action__person}>
          {((action.name === 'Remplacement' || action.name === 'REPLACEMENT') && action.personOut)
            ? `${action.personOut} par ${action.person}`
            : `${action.person}`}
        </div>
        <div className={s.container__action__team}>{action.team}</div>
      </div>
      {action.video && !action.name && (
      <div
        className={s.container__video}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: action.video }}
        role="presentation"
      />
      )}
      {action.video && action.name && (
      <div
        className={classNames(s.container__icon, 'fact_see_video')}
        onClick={displayVideo}
        onKeyPress={displayVideo}
        role="presentation"
      >
        <MdPlayCircleOutline />
        <span>Voir la video</span>
      </div>
      )}
      {action.image && (
      <div
        className={classNames(s.container__icon, 'fact_see_image')}
        onClick={displayImage}
        onKeyPress={displayImage}
        role="presentation"
      >
        <FaCreativeCommonsShare />
        <span>Voir l&apos;image</span>
      </div>
      )}
    </div>
  );
};

Fact.propTypes = {
  action: PropTypes.any,
  match: PropTypes.any,
  displayVideo: PropTypes.func,
  displayImage: PropTypes.func,
};

Fact.defaultProps = {
  action: undefined,
  match: undefined,
  displayVideo: undefined,
  displayImage: undefined,
};

export default Fact;
