import CardFact from './CardFact';
import Fact from './Fact';
import Header from './Header';
import ModalVideo from './ModalVideo';
import Status from './Status';

export default {
  CardFact,
  Fact,
  Header,
  ModalVideo,
  Status,
};
