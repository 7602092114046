import moment from 'moment';
import { liveTypes } from './actionTypes';

export const resetLives = () => ({
  type: liveTypes.RESET_LIVE,
});

export const setDate = (date) => ({
  type: liveTypes.SET_LIVE_DATE,
  payload: { date },
});

export const setMultiLiveDate = (liveDate) => {
  const todayDay = moment().format('DDDD');
  const actionDate = moment(liveDate).format('DDDD');
  let date;
  if (todayDay !== actionDate) {
    date = moment(liveDate).format('HH:mm');
  } else {
    date = moment(liveDate).fromNow();
  }
  return {
    type: liveTypes.SET_MULTI_LIVE_DATE,
    payload: { liveDate: date },
  };
};

export const setCurrentDate = (currentDate) => ({
  type: liveTypes.SET_LIVE_CURRENT_DATE,
  payload: { currentDate },
});

export const setIsLive = (isLive, liveType) => ({
  type: liveTypes.SET_LIVE_IS_LIVE,
  payload: { isLive, liveType },
});

export const setLiveCompetitions = (liveCompetitions, liveType) => ({
  type: liveTypes.SET_LIVE_LIVE_COMPETITIONS,
  payload: { liveCompetitions, liveType },
});

export const setCompetitions = (competitions, liveType) => ({
  type: liveTypes.SET_LIVE_COMPETITIONS,
  payload: { competitions, liveType },
});

export const setMatches = (matches, type, liveType) => ({
  type: liveTypes.SET_LIVE_MATCHES,
  payload: { liveType, type, matches },
});

export const setActions = (actions) => ({
  type: liveTypes.SET_LIVE_ACTIONS,
  payload: { actions },
});

export const sortMatches = () => ({
  type: liveTypes.SET_LIVE_SORT_MATCHES,
});

export const setIsLoading = (isLoading) => ({
  type: liveTypes.SET_LIVE_LOADING,
  payload: { isLoading },
});

export const setIsInit = (isInit) => ({
  type: liveTypes.SET_LIVE_IS_INIT,
  payload: { isInit },
});

export const setLiveTypeIsLoading = (liveType, isLoading) => ({
  type: liveTypes.SET_LIVE_TYPE_LOADING,
  payload: { liveType, isLoading },
});

export const setSelectedCompetition = (selectedCompetition) => ({
  type: liveTypes.SET_SELECTED_COMPETITION,
  payload: { selectedCompetition },
});

export const resetSearch = () => ({
  type: liveTypes.RESET_LIVE_SEARCH,
});

export const setSearchCompetition = (searchCompetition) => ({
  type: liveTypes.SET_LIVE_SEARCH_COMPETITION,
  payload: { searchCompetition },
});

export const setLiveFrom = (from) => ({
  type: liveTypes.SET_LIVE_FROM,
  payload: { from },
});

export const setLiveOnly = (isLiveOnly) => ({
  type: liveTypes.SET_LIVE_ONLY,
  payload: { isLiveOnly },
});
