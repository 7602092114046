import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';
import Item from './Item';
import s from './style.module.scss';

const ModalMultiLive = ({
  open, close, games, goals, date, competition, isIframe,
}) => (
  <div
    className={classNames(
      s.container,
      open ? s.open_container : undefined,

      isIframe ? s.container__iframe : undefined,
    )}
  >
    <div className={s.container__header}>
      <div className={s.container__header__info}>
        <div className={s.container__header__info__name}>
          {competition}
        </div>
        <div className={s.container__header__info__disclaimer}>
          {date}
        </div>
        {/* <div className={s.container__header__info__disclaimer}>
            16 matchs en cours
        </div> */}
      </div>
      <button
        className={s.container__header__close}
        type="button"
        onClick={close}
      >
        <FiX />
      </button>
    </div>
    {games !== undefined && games.map((i) => (
      <Item
        isIframe={isIframe}
        game={i}
        goals={goals.find((a) => a.match.id === i.id)}
        key={i.id}
      />
    ))}
  </div>
);

ModalMultiLive.propTypes = {
  open: PropTypes.bool,
  isIframe: PropTypes.bool,
  competition: PropTypes.string,
  close: PropTypes.func,
  games: PropTypes.any,
  goals: PropTypes.any,
  date: PropTypes.any.isRequired,
};

ModalMultiLive.defaultProps = {
  open: false,
  isIframe: false,
  competition: '',
  close: undefined,
  games: undefined,
  goals: undefined,
};

export default ModalMultiLive;
