import { connect } from 'react-redux';
import Root from './Root';

const mapStateToProps = (state) => {
  const { live } = state.entities;
  const { router } = state;

  return {
    live,
    currentPath: router.location.pathname,
  };
};

export default connect(
  mapStateToProps,
  null,
)(Root);
