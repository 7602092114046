import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({
  small,
  style, // eslint-disable-line react/prop-types
}) => (
  <div className="loading-container" style={style}>
    <svg className={small ? 'load-small' : 'load'} x="0px" y="0px" viewBox="0 0 150 150">
      <circle className="loading-inner" cx="75" cy="75" r="60" />
    </svg>
  </div>
);

Loading.propTypes = {
  small: PropTypes.bool,
};

Loading.defaultProps = {
  small: false,
};

export default Loading;
