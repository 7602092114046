/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { urlParam } from '../../helpers/utils';
import ArticleMatch from '../ArticleMatch';
import { getMatch } from '../../services/matches';

const Live = ({ dispatch }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [match, setMatch] = useState(null);
  const [iframe, setIframe] = useState(false);

  const { matchId } = useParams();
  const isIframe = urlParam('iframe');

  const fetchData = async () => {
    try {
      const matchLive = await dispatch(getMatch(matchId));
      setIframe(!!isIframe);
      matchLive.dataSourceName = 'LiveCollector';
      setMatch(matchLive);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    match != null && <ArticleMatch match={match} isIframe={iframe} />
  );
};

Live.defaultProps = {
  dispatch: null,
};

Live.propTypes = {
  dispatch: PropTypes.any,
};

Live.defaultProps = {
  dispatch: null,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  null,
  mapDispatchToProps,
)(Live);
