import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Layout from '../Layout';
import LoadingScreen from '../LoadingScreen';
import PageMultiLive from '../multilive';

import PageCompetitions from '../competitions';
import live from '../live';

import withTracker from '../withTracker';

import './root.css';

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isReady: false,
      error: '',
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isReady: true });
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  render() {
    const { location } = this.props;
    const { isModalOpen, isReady, error } = this.state;
    if (!isReady) {
      return (
        <div className="lds-container">
          <LoadingScreen size={60} />
        </div>
      );
    }
    return (
      <Layout
        location={location.pathname}
        isModalOpen={isModalOpen}
      >
        {error !== '' ? error : null}
        <ReactNotification />
        <Switch>
          <Route path="/" exact render={() => <Redirect to="/admin/competitions" />} />
          <Route path="/multilive/:championship/:division" exact component={PageMultiLive} />
          <Route path="/multilive" exact component={PageMultiLive} />
          <Route path="/live/:matchId" exact component={live} />
          <Route path="/admin/competitions" exact component={PageCompetitions} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Layout>
    );
  }
}

Root.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withTracker(Root);
