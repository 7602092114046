import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FiArrowUp, FiLoader,
} from 'react-icons/fi';
import moment from 'moment-timezone';

import { MdTimer } from 'react-icons/md';

import {
  getMatchAction, getMatchGoals, getTeamGoals,
} from '../../helpers/matchFacts';
import { getMatchDate } from '../../helpers/utils';
import isNative from '../../commons/checkPWA';
import Match from '../Match';
import Loading from '../Loading';
import s from './style.module.scss';

const ArticleMatch = ({
  match, actions, isIframe,
}) => {
  const [matchInterval, setMatchInterval] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [actionAsset, setActionAsset] = useState('');
  const [actionAssetVideo, setActionAssetVideo] = useState(false);
  const [content, setContent] = useState('resume');
  const [goTop, setGoTop] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [articleActions, setArticleActions] = useState([]);
  const [videos, setVideos] = useState([]);
  const [teams, setTeams] = useState(null);
  const [score, setScore] = useState(null);

  const handleScroll = () => {
    const { body } = document;
    let doc = document.documentElement;
    doc = (doc.clientHeight) ? doc : body;
    setGoTop((state) => {
      if (doc.scrollTop > 20) {
        if (state === 0) {
          return 1;
        }
      } else if (doc.scrollTop < 19) {
        if (state === 1) {
          return 0;
        }
      }
      return state;
    });
  };

  const getMatchActions = async (firstCall = false) => {
    const acts = await actions.getActions(match.id);
    const mScore = await actions.getMatchLiveScore(match.id);
    setScore(mScore || null);
    if (firstCall) {
      if (mScore && mScore.minute === 'FT' && matchInterval !== undefined) clearInterval(matchInterval);
    }
    const fixedActions = acts
      .filter((e) => e.name !== null)
      .map((a) => ({
        ...a,
        dateTime: getMatchDate(a.dateTime, match.dataSourceName).toDate(),
      }));
    setArticleActions(fixedActions);
    const videoF = acts.filter((a) => a.video !== null);
    setVideos(videoF.map((a) => ({
      name: 'video',
      video: a.video,
      person: a.person,
      actionId: a.dateTime,
    })));
  };

  const fetchMatchSquads = async (id) => {
    try {
      const data = await actions.getMatchSquads(id);
      setTeams(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchActions = async () => {
      try {
        await getMatchActions(true);
        setLoaded(true);
        if (match.isStarted && !match.isFinished) {
          setMatchInterval(setInterval(getMatchActions, 15000));
        }
      } catch (error) {
        setLoaded(true);
      }
    };
    const scrollListener = window.addEventListener('scroll', handleScroll);
    fetchActions();
    return () => {
      window.removeEventListener('scroll', scrollListener);
      if (matchInterval !== undefined) clearInterval(matchInterval);
    };
  }, []); // eslint-disable-line

  useEffect(() => function cleanup() {
    if (matchInterval !== undefined) clearInterval(matchInterval);
  }, [matchInterval]); // eslint-disable-line

  useEffect(() => {
    fetchMatchSquads(match.id);
  }, [match]);

  if (!loaded) {
    return (
      <div className={s.container}>
        <Loading small />
      </div>
    );
  }

  const mActions = articleActions
    .slice(0)
    .reverse()
    .filter((e) => e.name !== null);

  function closeModalActionAsset() {
    setModalOpen(false);
    setActionAsset('');
  }

  function displayModalAction(asset, video) {
    setActionAsset(asset);
    setModalOpen(true);
    if (video) {
      setActionAssetVideo(true);
    } else {
      setActionAssetVideo(false);
    }
  }

  function matchNoActions(d) {
    const start = getMatchDate(d, match.dataSourceName).toDate();
    const end = moment(start).add(200, 'm').toDate();
    const now = new Date(new Date().getTime());
    if (now > start && now < end) {
      return (
        <div className={s.container__match}>
          <FiLoader />
          Match en cours
          <div className={s.container__match__disclaimer}>
            non-commenté
          </div>
        </div>
      );
    } if (now < start) {
      return (
        <div className={s.container__match}>
          <MdTimer />
          Coup d&apos;envoi le
          {' '}
          {getMatchDate(d, match.dataSourceName).format('D MMM YYYY [à] H:mm')}
        </div>
      );
    } if (now > end) {
      return (
        <div className={s.container__match}>
          <MdTimer />
          {`Passé: ${getMatchDate(d, match.dataSourceName).format('D MMM YYYY [à] H:mm')}`}
          <div className={s.container__match__disclaimer}>
            non-commenté
          </div>
        </div>
      );
    } return (
      <div className={s.container__match}>
        <MdTimer />
        Coup d&apos;envoi le
        {' '}
        {getMatchDate(d, match.dataSourceName).format('D MMM YYYY [à] H:mm')}
      </div>
    );
  }

  function playersFacts(player) {
    return (
      mActions
        .filter((a) => a.person === player.name || a.personOut === player.name)
        .filter((e) => (
          e.name === 'But' || e.name === 'GOAL'
          || e.name === 'Jaune' || e.name === 'YELLOW_CARD'
          || e.name === 'Rouge' || e.name === 'RED_CARD'
          || e.name === 'Remplacement' || e.name === 'REPLACEMENT'
          || e.name === 'Rouge (direct)' || e.name === 'Rouge (2x jaune)'
        ))
        .map((e) => (
          <span
            key={e.actionId}
            style={{
              color: getMatchAction(e.name, match.dataSourceName).color === '#000'
               && getMatchAction(e.name, match.dataSourceName).color,
              marginLeft: 5,
            }}
          >
            {getMatchAction(e.name, match.dataSourceName).icon}
          </span>
        ))
    );
  }

  function getPlayers(team, type) {
    return (
      teams[team][type].sort((a, b) => a.number - b.number).map((p) => (
        <p key={p.name}>
          <b>
            {p.number}
            &nbsp;
          </b>
          <span>{p.name}</span>
          {playersFacts(p).length > 0 && playersFacts(p)}
        </p>
      ))
    );
  }

  const goals = getMatchGoals(articleActions);
  const homeTeamGoals = getTeamGoals(goals, 'home', teams);
  const awayTeamGoals = getTeamGoals(goals, 'visitors', teams);

  function pageContent() {
    if (content === 'all' || content === 'resume') {
      if (mActions.length > 0) {
        return (
          <>
            <div
              onKeyPress={() => setContent(content === 'all' ? 'resume' : 'all')}
              onClick={() => setContent(content === 'all' ? 'resume' : 'all')}
              role="presentation"
              className={s.container__switchMatch}
            >
              {content === 'all'
                ? 'afficher les temps forts'
                : 'afficher tous les moments'}
            </div>
            {content === 'resume'
              ? mActions
                .map((action) => getMatchAction(action.name, match.dataSourceName).display && (
                  <Match.CardFact
                    key={action.actionId}
                    action={action}
                    match={match}
                    goals={goals}
                    homeTeamGoals={homeTeamGoals}
                    awayTeamGoals={awayTeamGoals}
                    squad={teams}
                  />
                )) : mActions
                .map((action) => getMatchAction(action.name, match.dataSourceName) && (
                <Match.Fact
                  key={action.actionId}
                  action={action}
                  match={match}
                  displayVideo={action.video !== null
                    ? () => displayModalAction(action.video, true)
                    : undefined}
                  displayImage={action.image !== null
                    ? () => displayModalAction(action.image, false)
                    : undefined}
                />
                ))}
          </>
        );
      }
    } else if (content === 'players') {
      if (teams?.homeLineUp?.starters.length > 0) {
        return (
          <div className={s.container__teamsTable}>
            <div className={s.container__teamsTable__teams}>
              <div className={s.container__teamsTable__teams__team}>
                {getPlayers('homeLineUp', 'starters')}
                <div className={s.container__teamsTable__teams__subs}>Réservistes</div>
                {getPlayers('homeLineUp', 'substitutes')}
              </div>
              <div className={s.container__teamsTable__teams__team}>
                {getPlayers('visitorLineUp', 'starters')}
                <div className={s.container__teamsTable__teams__subs}>Réservistes</div>
                {getPlayers('visitorLineUp', 'substitutes')}
              </div>
            </div>
          </div>
        );
      } return null;
    } else if (content === 'videos') {
      return videos.map((action) => (
        <Match.CardFact
          key={action.actionId}
          action={action}
          match={match}
          homeTeamGoals={homeTeamGoals}
          awayTeamGoals={awayTeamGoals}
          squad={teams}
        />
      ));
    }
    // else if (content === 'results') {
    //   if (league !== null) {
    //     return (
    //       <div
    //         className="sportResults"
    //         // eslint-disable-next-line react/no-danger
    //         dangerouslySetInnerHTML={{ __html: league.rankings.content }}
    //       />
    //     );
    //   } return null;
    // }
    return (
      <div className={s.container}>
        {matchNoActions(match.matchDate)}
      </div>
    );
  }
  return (
    <div className={s.container}>
      <Match.Header
        match={match}
        matchActions={articleActions}
        goals={goals}
        score={score}
        homeTeamGoals={homeTeamGoals}
        awayTeamGoals={awayTeamGoals}
        isIframe={isIframe}
      />
      <div className={s.container__matchNav}>
        <ul className={s.container__matchNav__menu}>
          <li
            onKeyPress={() => setContent('resume')}
            onClick={() => setContent('resume')}
            role="presentation"
            className={content === 'resume' || content === 'all' ? s.container__matchNav__menu__active : undefined}
          >
            Match
          </li>
          {teams?.homeLineUp?.starters.length > 0
            && teams?.visitorLineUp?.starters.length > 0
            && (
              <li
                onKeyPress={() => setContent('players')}
                onClick={() => setContent('players')}
                role="presentation"
                className={content === 'players' ? s.container__matchNav__menu__active : undefined}
              >
                Composition
              </li>
            )}
          {videos.length > 0
            && (
              <li
                onKeyPress={() => setContent('videos')}
                onClick={() => setContent('videos')}
                role="presentation"
                className={content === 'videos' ? s.container__matchNav__menu__active : undefined}
              >
                Vidéos
              </li>
            )}
          {/* TODO: Fix this menu and add results back */}
          {/* {league !== null
            && (
              <li
                onKeyPress={() => setContent('results')}
                onClick={() => setContent('results')}
                role="presentation"
  eslint-disable-next-line max-len
                className={content === 'results' ? s.container__matchNav__menu__active : undefined}
              >
                Résultats
              </li>
            )} */}
        </ul>
        <div className={s.container__matchNav__actions}>
          <Match.Status match={match} dataSourceName={match.dataSourceName} />
        </div>
      </div>
      {/* { isPremium && access.type !== 'SUBSCRIBER' ? (
        <ModalRegister
          type="need_sub"
          isArticle
          isMatch
        />
      ) : pageContent()} */}
      {pageContent()}
      <Match.ModalVideo
        open={modalOpen}
        close={() => closeModalActionAsset()}
      >
        {actionAssetVideo
          ? (
            <div
              className={s.container__matchFacts}
              onClick={() => closeModalActionAsset()}
              onKeyPress={() => closeModalActionAsset()}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: actionAsset }}
              role="presentation"
            />
          )
          : <img src={actionAsset} alt="Avenir" />}
      </Match.ModalVideo>
      {articleActions.length > 0 && (
        <div
          className={s.container__goTop}
          style={{ opacity: goTop, bottom: isNative && !isIframe ? 70 : 20 }}
          onClick={() => window.scrollTo(0, 0)}
          onKeyPress={() => window.scrollTo(0, 0)}
          role="presentation"
        >
          <FiArrowUp />
        </div>
      )}
    </div>
  );
};

ArticleMatch.propTypes = {
  isIframe: PropTypes.bool.isRequired,
  match: PropTypes.any.isRequired,
  actions: PropTypes.shape({
    getActions: PropTypes.func.isRequired,
    getMatchSquads: PropTypes.func.isRequired,
    getMatchLiveScore: PropTypes.func.isRequired,
  }).isRequired,
};

export default ArticleMatch;
