/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import PullToRefresh from 'react-simple-pull-to-refresh';
import PropTypes from 'prop-types';
import moment from 'moment';
import Media from 'react-media';
import queryString from 'query-string';
import { useParams } from 'react-router-dom';

import { FaMoon } from 'react-icons/fa';
import VizSensor from 'react-visibility-sensor';
import { urlParam, getMatchDate, randomUUid } from '../../helpers/utils';
import {
  initMultiLivePage, changeSelectedCompetition, getMatchesFacts, getLives,
} from '../../services/live';
import { setCurrentDate, setMultiLiveDate, setDate } from '../../actions/liveActions';
import { setFixedHeader, setActiveSection } from '../../actions/appActions';
import ModalMultiLive from '../ModalMultiLive';
import useInterval from '../../hooks/useSetInterval';

import ScrollWrapper from '../ScrollWrapper';
import ScrollCalendar from '../ScrollCalendar';
import CardFact from '../Match/CardFact';
import Loading from '../Loading';

import s from './style.module.scss';

const types = [
  { name: 'user', title: 'Mes Compétitions' },
  { name: 'redac', title: 'Les plus populaires' },
];

const PageMultiLive = ({
  actions, live, router,
}) => {
  const [open, setOpen] = useState(true);
  const queryParams = queryString.parse(router.location.search);
  const divisions = urlParam('divisions');
  const matchs = urlParam('matchs');
  const fromUrlParam = urlParam('from');
  const arrayDivisions = divisions != null ? divisions.split(',') : [];
  const arrayMatchs = matchs != null ? matchs.split(',') : [];
  const selected = {
    arrayDivisions,
    arrayMatchs,
  };
  const [selectedParams, setSelectedParams] = useState(selected);
  const isIframe = queryParams.iframe === 'true';
  const dateFromUrl = queryParams.date;

  useEffect(() => {
    // eslint-disable-next-line prefer-regex-literals
    const regex = RegExp(/^\d{4}-\d{2}-\d{2}$/);
    if (dateFromUrl !== null && regex.test(dateFromUrl) && dateFromUrl !== live.date) {
      actions.setDate(dateFromUrl);
    }
  }, []);

  useEffect(() => {
    actions.setActiveSection('live');
    actions.setFixedHeader(true);
    if ((live.date !== live.currentDate || live.multiLive.actions.length === 0)
      && !live.isLoading) {
      actions.setCurrentDate(live.date);
      actions.initMultiLivePage(selected, fromUrlParam !== null
        ? fromUrlParam
        : false, live.date, isIframe);
    }
    return () => actions.setFixedHeader(false);
  }, [live.date]); // eslint-disable-line

  useInterval(() => {
    live.multiLive.liveCompetitions.map((c) => actions.getLives(selectedParams, c.type, 'multiLive'));
    actions.getMatchesFacts(true);
  }, live.multiLive.isLive ? 15000 : null);

  const handleRefresh = () => {
    actions.initMultiLivePage(
      selected,
      fromUrlParam !== null ? fromUrlParam : false,
      live.date,
      isIframe,
    );
  };

  const handleFactScroll = () => {
    const visibleFacts = document.querySelector('.action_visible');
    if (visibleFacts !== undefined && visibleFacts !== null && live.multiLive.actions.length > 0) {
      const fId = visibleFacts.getAttribute('data-id');
      const fact = live.multiLive.actions.find((f) => f.actionId === Number(fId));
      const todayDay = moment().format('DDDD');
      const actionDate = getMatchDate(fact.dateTime, fact.match.dataSourceName).format('DDDD');
      let date;
      if (todayDay !== actionDate) {
        date = getMatchDate(fact.dateTime, fact.match.dataSourceName).format('HH:mm');
      } else {
        date = getMatchDate(fact.dateTime, fact.match.dataSourceName).fromNow();
      }
      if (live.liveDate !== date) {
        actions.setMultiLiveDate(getMatchDate(fact.dateTime, fact.match.dataSourceName));
      }
    }
  };

  function openModal(mobile) {
    if (mobile) {
      setOpen(true);
    } else {
      window.scrollTo(0, 0);
      setOpen(true);
    }
  }

  const handleCompetitionChange = (e) => {
    // eslint-disable-next-line no-shadow
    const [type, d] = e.target.value.split('-|-');
    const compet = live[type].competitions.find((c) => c.division === d);
    actions.changeSelectedCompetition(compet, type);
    router.push(
      '/multilive/[championship]/[division]',
      `/multilive/${encodeURI(compet.championship).replace('/', '%2F')}/${encodeURI(compet.division).replace('/', '%2F')}?from=${type}`,
    );
  };

  // if (championship === 'undefined' || championship === undefined) {
  //   return router.push('/live');
  // }

  return (
    <Media queries={{
      small: '(max-width: 720px)',
    }}
    >
      {(matches) => (
        <div
          className={s.container}
          // eslint-disable-next-line no-nested-ternary
          style={{ marginTop: matches.small && !isIframe ? 100 : 0 }}
        >
          <PullToRefresh onRefresh={handleRefresh}>
            <ScrollCalendar className={
            classNames(s.container__calendar, isIframe ? s.container__calendar__iframe : undefined)
            }
            />
            {/* {isIframe
              && (
                open && (
                  <div
                    className={s.container__division}
                    style={{ marginTop: matches.small ? 34 : 0 }}
                  >
                    {live.selectedCompetition?.division}
                  </div>
                )
              )} */}

            {!open && (
            <div className={
              classNames(s.container__header, isIframe ? s.container__header__iframe : undefined)
              }
            >
              <div
                onClick={() => openModal(matches.small)}
                onKeyPress={() => openModal(matches.small)}
                role="presentation"
                className={s.container__header__resume}
              >
                {live.date === moment().format('YYYY-MM-DD') && <b>En direct</b>}
                <span>Voir le résumé</span>
              </div>
              <div className={s.container__header__time}>
                {live.liveDate}
              </div>
            </div>
            )}
            <ModalMultiLive
              open={open}
              competition={live.selectedCompetition !== undefined
                ? live.selectedCompetition.division : ''}
              date={moment(live.currentDate).format('LL')}
              close={() => setOpen(false)}
              games={live.multiLive.matches}
              goals={live.multiLive.actions}
              isIframe={isIframe}
            />
            {!open && (
              <div>
                <br />
                <br />
              </div>
            )}
            {live.selectedCompetition !== undefined && !live.isLoading
              ? (
                <ScrollWrapper onScrollHandler={handleFactScroll}>
                  {live.multiLive.actions.length ? live.multiLive.actions.map((a) => (
                    <VizSensor
                      key={a.actionId + randomUUid()}
                    >
                      {({ isVisible }) => (
                        <div style={{ borderBottom: '1px dashed #b1b1b1', borderTop: '1px dashed #b1b1b1', margin: '10px' }} className={isVisible ? 'action_visible' : ''} data-id={a.actionId}>
                          <CardFact
                            isMultiLive
                            action={a}
                            match={a.match}
                            goals={a.goals}
                            homeTeamGoals={a.homeTeamGoals}
                            awayTeamGoals={a.awayTeamGoals}
                            squad={a.match.squad}
                          />
                        </div>
                      )}
                    </VizSensor>
                  ))
                    : (
                      <div className={s.container__noMatches}>
                        <FaMoon />
                        <p>Aucun résultat trouvé...</p>
                      </div>
                    )}
                </ScrollWrapper>
              ) : <div className={s.container__loader}><Loading small /></div>}
          </PullToRefresh>
        </div>
      )}
    </Media>
  );
};

PageMultiLive.propTypes = {
  actions: PropTypes.shape({
    setFixedHeader: PropTypes.func.isRequired,
    initMultiLivePage: PropTypes.func.isRequired,
    setCurrentDate: PropTypes.func.isRequired,
    changeSelectedCompetition: PropTypes.func.isRequired,
    setMultiLiveDate: PropTypes.func.isRequired,
    getLives: PropTypes.func.isRequired,
    getMatchesFacts: PropTypes.func.isRequired,
    setDate: PropTypes.func.isRequired,
    setActiveSection: PropTypes.func.isRequired,
  }).isRequired,
  live: PropTypes.any.isRequired,
  router: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  const { live } = state.entities;
  const { router } = state;
  return {
    live,
    router,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    setFixedHeader: bindActionCreators(setFixedHeader, dispatch),
    initMultiLivePage: bindActionCreators(initMultiLivePage, dispatch),
    setCurrentDate: bindActionCreators(setCurrentDate, dispatch),
    changeSelectedCompetition: bindActionCreators(changeSelectedCompetition, dispatch),
    setMultiLiveDate: bindActionCreators(setMultiLiveDate, dispatch),
    getLives: bindActionCreators(getLives, dispatch),
    getMatchesFacts: bindActionCreators(getMatchesFacts, dispatch),
    setDate: bindActionCreators(setDate, dispatch),
    setActiveSection: bindActionCreators(setActiveSection, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageMultiLive);
