import { appTypes } from '../../actions/actionTypes';

const defaultMatch = {
  teamHomeColor: '#000',
  teamAwayColor: '#000',
};

const defaultState = {
  match: defaultMatch,
};

// eslint-disable-next-line default-param-last
const match = (state = defaultState, action) => {
  switch (action.type) {
    case appTypes.RESET_MATCH:
      return { ...state, match: defaultMatch };
    case appTypes.SET_MATCH_COLOR:
      return {
        ...state,
        match: {
          ...state.match,
          [action.payload.type]: `rgb(${action.payload.color})`,
        },
      };
    default:
      return state;
  }
};

export default match;
