import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import entities from './entities';

const createRootReducer = (history) => combineReducers({
  entities,
  router: connectRouter(history),
});

export default createRootReducer;
