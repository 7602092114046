import React from 'react';
import {
  FaCircle, FaExchangeAlt, FaHandPaper, FaRegCommentDots, FaFile, FaVideo, FaArrowsAlt,
} from 'react-icons/fa';
import { IoMdFootball } from 'react-icons/io';
import {
  MdFlag, MdTimer, MdGpsFixed,
} from 'react-icons/md';
import { TiFlash } from 'react-icons/ti';

const livecoll = {
  COMMENT: {
    name: 'Commentaire',
    display: true,
    icon: <FaRegCommentDots />,
    hierarchy: 'medium',
    color: '#000',
  },
  REPLACEMENT: {
    name: 'Remplacement',
    display: true,
    icon: <FaExchangeAlt />,
    hierarchy: 'medium',
    color: '#000',
  },
  CORNER: {
    name: 'Corner',
    display: false,
    icon: <MdFlag />,
    hierarchy: 'medium',
    color: '#000',
  },
  YELLOW_CARD: {
    name: 'Carton jaune',
    display: true,
    icon: <FaFile />,
    hierarchy: 'medium',
    color: '#ffe421',
  },
  RED_CARD: {
    name: 'Carton rouge',
    display: true,
    icon: <FaFile />,
    hierarchy: 'medium',
    color: '#FF1E1E',
  },
  coupfranc: {
    name: 'Coup franc',
    display: true,
    icon: <MdGpsFixed />,
    hierarchy: 'medium',
    color: '#000',
  },
  GOAL: {
    name: 'But !',
    display: true,
    icon: <IoMdFootball />,
    hierarchy: 'high',
    color: '#000',
  },
  OWNGOAL: {
    name: 'But contre son camp',
    display: true,
    icon: <IoMdFootball />,
    hierarchy: 'high',
    color: '#000',
  },
  OPPORTUNITY: {
    name: 'Occasion',
    display: false,
    icon: <FaCircle />,
    hierarchy: 'medium',
    color: '#000',
  },
  PENALTY: {
    name: 'Pénalty',
    display: true,
    icon: <TiFlash />,
    hierarchy: 'medium',
    color: '#000',
  },
  TENSION: {
    name: 'Tension',
    display: false,
    icon: <TiFlash />,
    hierarchy: 'medium',
    color: '#000',
  },
  FREEKICK: {
    name: 'Coup franc',
    display: true,
    icon: <FaArrowsAlt />,
    hierarchy: 'medium',
    color: '#000',
  },
  FOUL: {
    name: 'Faute',
    display: false,
    icon: <FaHandPaper />,
    hierarchy: 'medium',
    color: '#000',
  },
  KICKOFF: {
    name: 'Début du match',
    display: true,
    icon: <MdTimer />,
    hierarchy: 'low',
    color: '#000',
  },
  HALFTIME: {
    name: 'Mi-temps',
    display: true,
    icon: <MdTimer />,
    hierarchy: 'low',
    color: '#000',
  },
  STARTSECONDHALF: {
    name: 'Début deuxième période',
    display: false,
    icon: <MdTimer />,
    hierarchy: 'low',
    color: '#000',
  },
  EXTRATIME: {
    name: 'Début des prolongation',
    display: false,
    icon: <MdTimer />,
    hierarchy: 'low',
    color: '#000',
  },
  HALFTIMEXTRA: {
    name: 'Mi-temps des prolongations',
    display: true,
    icon: <MdTimer />,
    hierarchy: 'low',
    color: '#000',
  },
  STARTSECONDXTRA: {
    name: 'Début deuxième période des prolongations',
    display: false,
    icon: <MdTimer />,
    hierarchy: 'low',
    color: '#000',
  },
  END_EXTRATIME: {
    name: 'Fin des prolongations',
    display: false,
    icon: <MdTimer />,
    hierarchy: 'low',
    color: '#000',
  },
  GAMEOVER: {
    name: 'Fin de match',
    display: true,
    icon: <MdTimer />,
    hierarchy: 'low',
    color: '#000',
  },
  video: {
    display: true,
    name: 'Vidéo',
    hierarchy: 'medium',
    color: '#000',
    icon: <FaVideo />,
  },
};

export const livecollEndOfPeriods = [
  'HALFTIME',
  'EXTRATIME',
  'STARTSECONDXTRA',
  'GAMEOVER',
];

export const isFactGoal = ['GOAL', 'OWNGOAL', 'But', 'But contre son camp'];

/**
 * isGoal check if the fact is of goal type
 * @param {*} fact
 */
export const isGoal = (fact) => isFactGoal.indexOf(fact.name) !== -1;

/**
 * isOwnGoal check if the fact is of auto goal type
 * @param {*} fact
 */
export const isOwnGoal = (fact) => ['OWNGOAL', 'But contre son camp'].indexOf(fact.name) !== -1;

export const isKickOfFact = (fact) => fact.name === "Coup d'envoi" || fact.name === 'KICKOFF';

export const isEndOfPeriodFact = (fact) => fact.name === 'Fin de période' || fact.name === 'HALFTIME' || fact.name === 'GAMEOVER';

export const getMatchAction = (name) => {
  if (name === null || name === undefined) {
    return {
      display: false,
      name,
      icon: <FaExchangeAlt />,
      hierarchy: 'low',
      color: '#000',
    };
  }
  const action = livecoll[name.replace(/-|\s/g, '')];
  if (action !== undefined) return action;
  return {
    display: false,
    name,
    icon: <FaExchangeAlt />,
    hierarchy: 'low',
    color: '#000',
  };
};

export const getMatchPeriods = (actions, dataSourceName) => {
  if (dataSourceName === 'LiveCollector') {
    const ps = actions.filter((g) => livecollEndOfPeriods.indexOf(g.name) !== -1);
    if (actions.indexOf(ps[ps.length - 1]) < actions.length - 1) {
      // Last period is not finished
      ps.push({
        name: 'ongoing_period',
        actionTime: actions[actions.length - 1].actionTime,
      });
    }
    return ps;
  } if (dataSourceName === 'Infostrada') {
    const ps = actions.filter((g) => g.name === 'Fin de période');
    if (actions.indexOf(ps[ps.length - 1]) < actions.length - 1) {
      // Last period is not finished
      ps.push({
        name: 'ongoing_period',
        actionTime: actions[actions.length - 1].actionTime,
      });
    }
    return ps;
  }
  return [];
};

export const getEndOfMatchMin = (matchPeriods, dataSourceName) => {
  let endOfMatchMin = 0;
  if (dataSourceName === 'Infostrada' && matchPeriods.length) {
    endOfMatchMin = matchPeriods[matchPeriods.length - 1].actionTime;
  } else if (matchPeriods.length) {
    endOfMatchMin = Number(
      matchPeriods.find((a) => a.name === 'GAMEOVER') !== undefined
        ? matchPeriods.find((a) => a.name === 'GAMEOVER').actionTime
        : matchPeriods[matchPeriods.lenght > 1 ? matchPeriods.length - 1 : 0].actionTime,
    ) * 60;
  }
  return endOfMatchMin;
};

/**
 * getMatchGoals return all goals from match actions
 * @param {Array} actions
 */
export const getMatchGoals = (actions) => actions.filter((g) => isGoal(g));

/**
 * getTeamGoals return all team goals from match goals
 * @param {Array} goals
 * @param {String} type
 * @param {*} squads
 * @returns {Array}
 */
export const getTeamGoals = (goals, type, squads) => goals.filter((g) => {
  if (squads === null) return undefined;
  if (isOwnGoal(g)) {
    return (
      squads[type === 'home' ? 'visitorTeam' : 'homeTeam'].players.find((e) => e.name === g.person)
    );
  }
  return (
    squads[type === 'home' ? 'homeTeam' : 'visitorTeam'].players.find((e) => e.name === g.person)
  );
});
