import authFetch from '../helpers/authRequest';
import { endpoints } from '../api';

const footballOrder = [
  'les plus populaires',
  "a l'étranger",
  'brabant',
  'hainaut',
  'liège',
  'luxembourg',
  'namur',
  'national',
];

const sortByFootbalOrder = (e1, e2) => footballOrder
  .indexOf(e1.toLowerCase()) - footballOrder.indexOf(e2.toLowerCase());

export const getCompetitionsByTopicId = (id) => async (dispatch) => {
  const res = await dispatch(authFetch(endpoints.getTeamCompetitions + id, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  }));
  const data = await res.json();
  if (!res.ok) throw data;
  // const topicCalls = data.map((c) => dispatch(searchCompetitions(c.division)));
  // const topics = await Promise.all(topicCalls);
  // return data.map((d, i) => ({ ...d, topic: topics[i][0] }));
};

export const getRankings = (query) => async (dispatch) => {
  try {
    const res = await dispatch(authFetch(endpoints.getRankings + query, {
      method: 'GET',
    }));
    const data = await res.json();
    if (!res.ok) throw data;
    return data.sort((a, b) => {
      if (a.division < b.division) { return -1; }
      if (a.division > b.division) { return 1; }
      return 0;
    }).filter((v, i, a) => a.findIndex((t) => (t.topicId === v.topicId)) === i);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getFilters = (query) => async (dispatch) => {
  try {
    const res = await dispatch(authFetch(endpoints.getRankingsFilters + query, {
      method: 'GET',
    }));
    const data = await res.json();
    if (!res.ok) throw data;
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getFootballFilters = (query) => async (dispatch) => {
  try {
    if (query === '/Countries') {
      const res = await dispatch(authFetch(`${endpoints.getFootRankingsFilters}/Countries`, {
        method: 'GET',
      }));
      const data = await res.json();
      if (!res.ok) throw data;
      const resRegions = await dispatch(authFetch(`${endpoints.getFootRankingsFilters}/Regions?country=En Belgique`, {
        method: 'GET',
      }));
      const regions = await resRegions.json();
      if (!resRegions.ok) throw regions;
      return data
        .filter((e) => e.name !== 'En Belgique')
        .concat(regions)
        .map((o) => (o.name === 'Best' ? 'Les plus populaires' : o.name))
        .sort(sortByFootbalOrder);
    }
    const res = await dispatch(authFetch(endpoints.getFootRankingsFilters + query, {
      method: 'GET',
    }));
    const data = await res.json();
    if (!res.ok) throw data;
    return query.includes('Leagues') ? data.filter((v, i, a) => a.findIndex((t) => (t.topicId === v.topicId)) === i) : data.map((d) => d.name);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getHandballFilters = () => async (dispatch) => {
  try {
    const data = await dispatch(getFilters('?filterName=category&sportType=Handball'));
    const rankings = await dispatch(getRankings('?sportType=Handball'));
    return rankings.filter((e) => e.division === 'Bene League').concat(data.filter((e) => e !== 'Beneleague'));
  } catch (error) {
    throw new Error(error.message);
  }
};
