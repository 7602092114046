/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IoMdFootball } from 'react-icons/io';
import { GiTShirt } from 'react-icons/gi';
import { FiChevronDown } from 'react-icons/fi';
import Media from 'react-media';
import ColorThief from 'colorthief';
import classNames from 'classnames';
import { isOwnGoal, getMatchPeriods, getEndOfMatchMin } from '../../../helpers/matchFacts';
import { getMatchDate } from '../../../helpers/utils';
import { setMatchColor } from '../../../actions/appActions';
import s from './style.module.scss';

const Header = ({
  matchActions, match, actions, homeTeamGoals, awayTeamGoals, goals, score, isIframe,
}) => {
  function teamsName(name) {
    if (name.length >= 10 && name.length < 14) {
      return s.container__body__teams__team__mediumTeamName;
    } if (name.length >= 14) {
      return s.container__body__teams__team__bigTeamName;
    }
    return s.container__body__teams__team__smallTeamName;
  }

  const getColor = () => {
    try {
      console.log('');
      const colorThief = new ColorThief();
      const homeImg = document.querySelector('.match__home__logo');
      if (homeImg) {
        if (homeImg.complete) {
          actions.setMatchColor('teamHomeColor', colorThief.getColor(homeImg));
        } else {
          homeImg.addEventListener('load', () => {
            actions.setMatchColor('teamHomeColor', colorThief.getColor(homeImg));
          });
        }
      }
      const awayImg = document.querySelector('.match__away__logo');
      if (awayImg) {
        if (awayImg.complete) {
          actions.setMatchColor('teamAwayColor', colorThief.getColor(awayImg));
        } else {
          awayImg.addEventListener('load', () => {
            actions.setMatchColor('teamAwayColor', colorThief.getColor(awayImg));
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getColor();
  }, []); // eslint-disable-line

  // TimeLine match facts

  const periods = getMatchPeriods(matchActions, match.dataSourceName);

  const getFactTime = (f) => (match.dataSourceName === 'LiveCollector' ? (Number(f.actionTime) * 60) : f.actionTime);
  // eslint-disable-next-line max-len
  const getPeriodPercent = (start, end) => ((end - start) / getEndOfMatchMin(periods, match.dataSourceName)) * 100;
  // eslint-disable-next-line max-len
  const convertMinToPercent = (min) => (min / getEndOfMatchMin(periods, match.dataSourceName)) * 100;
  const goto = () => {

  };

  return (
    <Media queries={{
      small: '(max-width: 720px)',
    }}
    >
      {(matches) => (
        <div key={matches} className={s.container}>
          <div className={
            classNames(s.container__body, isIframe ? s.container__body__iframe : undefined)
          }
          >
            <div
              className={s.container__body__teams}
              role="presentation"
            >
              <div className={s.container__body__teams__team}>
                <h5
                  className={teamsName(match.teamHome.name)}
                  onClick={() => goto('home')}
                  onKeyPress={() => goto('home')}
                  role="presentation"
                >
                  {match.teamHome.name}
                </h5>
                <div
                  className={s.container__body__teams__team__img}
                  onClick={() => goto('home')}
                  onKeyPress={() => goto('home')}
                  role="presentation"
                >
                  {match.teamHome.teamLogoUrl
                    ? (
                      <img
                        crossOrigin="anonymous"
                        className="match__home__logo"
                        src={match.teamHome.teamLogoUrl}
                        alt={match.teamHome.name}
                      />
                    )
                    : <GiTShirt />}
                </div>
                {(score !== null ? score.homeGoals : match.homeGoals)}
              </div>
              <span>-</span>
              <div className={s.container__body__teams__team}>
                {(score !== null ? score.awayGoals : match.awayGoals)}
                <div
                  className={s.container__body__teams__team__img}
                  onClick={() => goto('away')}
                  onKeyPress={() => goto('away')}
                  role="presentation"
                >
                  {match.teamAway.teamLogoUrl
                    ? (
                      <img
                        crossOrigin="anonymous"
                        className="match__away__logo"
                        src={match.teamAway.teamLogoUrl}
                        alt={match.teamAway.name}
                      />
                    )
                    : <GiTShirt />}
                </div>
                <h5
                  className={teamsName(match.teamAway.name)}
                  onClick={() => goto('away')}
                  onKeyPress={() => goto('away')}
                  role="presentation"
                >
                  {match.teamAway.name}
                </h5>
              </div>
            </div>
            {matchActions.length > 0
            && periods.length > 0
            && (match.isStarted || match.isFinished)
            && (
              <>
                <div className={classNames(s.container__body__scores, s.container__body__teams)}>
                  <div className={s.container__body__teams__goal}>
                    {homeTeamGoals.length > 0 && (
                      homeTeamGoals.map((h) => (
                        <span key={`goal${h.actionId}`}>
                          {`${h.person} ${h.actionTime}' ${isOwnGoal(h) ? ' (contre son camp)' : ''}`}
                        </span>
                      ))
                    )}
                  </div>
                  <div className={s.container__body__teams__goal}>
                    {awayTeamGoals.length > 0 && (
                      awayTeamGoals.map((g) => (
                        <span key={`goal${g.actionId}`}>
                          {`${g.person} ${g.actionTime}' ${isOwnGoal(g) ? ' (contre son camp)' : ''}`}
                        </span>
                      ))
                    )}
                  </div>
                </div>
                <div className={s.container__body__timeline}>
                  <div className={s.container__body__timeline__facts}>
                    {goals.map((g) => (
                      <div
                        key={`timeLinegoal${g.actionId}`}
                        style={{ left: `calc(${convertMinToPercent(getFactTime(g))}% - 8px)` }}
                        className={s.container__body__timeline__facts__icon}
                      >
                        <IoMdFootball />
                        <FiChevronDown />
                      </div>
                    ))}
                  </div>
                  <div className={s.container__body__timeline__line}>
                    {periods.map((action, i) => (
                      <span
                        key={`timeline${action.actionId}`}
                        className={classNames(s.container__body__timeline__line__period, action.name === 'ongoing_period' ? s.container__body__timeline__line__period__ongoing : '')}
                        style={{
                          width: `${getPeriodPercent(i > 0 ? getFactTime(periods[i - 1]) : 0, getFactTime(action))}%`,
                        }}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}

            <div className={s.container__body__division}>
              <b>{getMatchDate(match.matchDate, match.dataSourceName).format('ddd D[/]M')}</b>
              <br />
              <b
                onClick={() => goto('league')}
                onKeyPress={() => goto('league')}
                role="presentation"
              >
                {match.competition && `${match.competition} ${match.division ? ` - ${match.division}` : ''}`}
              </b>
              <br />
              <span>
                {`${match.stadium !== null && match.stadium !== undefined ? `Stade: ${match.stadium}, ${match.city}` : ''}`}
                {`${match.city !== null && match.city !== undefined ? `Ville: ${match.city}` : ''}`}
                {`${match.refereeName ? ` / Arbitre: ${match.refereeName}` : ''}`}
              </span>
            </div>
          </div>
        </div>
      )}
    </Media>
  );
};

Header.propTypes = {
  score: PropTypes.any,
  isIframe: PropTypes.bool,
  match: PropTypes.any.isRequired,
  matchActions: PropTypes.array.isRequired,
  goals: PropTypes.array.isRequired,
  homeTeamGoals: PropTypes.array.isRequired,
  awayTeamGoals: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    setMatchColor: PropTypes.func.isRequired,
  }).isRequired,
};

Header.defaultProps = {
  score: null,
  isIframe: false,
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    setMatchColor: bindActionCreators(setMatchColor, dispatch),
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(Header);
