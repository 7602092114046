const authFetch = (url, options) => async () => {
  const reqOptions = options || {};
  reqOptions.headers = reqOptions.headers || {};
  // const token = getState().access.accessToken;
  // if (token) reqOptions.headers.Authorization = `Bearer ${token}`;
  const response = await fetch(url, reqOptions);
  if (response.ok) {
    return response;
  }
  if (response.status === 401) {
    // if (retry <= 3 && token) {
    //   return dispatch(authFetch(url, reqOptions, retry + 1));
    // } if (token) {
    //   const contentType = response.headers.get('content-type');
    //   if (contentType && contentType.indexOf('application/json') !== -1) {
    //     const data = await response.json();
    //     console.log('data', data);
    //   }
    //   if (process.env.REACT_APP_ENV === 'DEV') {
    //     alert('logout');
    //     console.log(response);
    //   } else {
    //     window.location.reload();
    //   }
    // }
    throw new Error('need_auth');
  } else if (response.status === 403) {
    throw new Error('need_sub');
  } else if (response.status === 429) {
    throw new Error('too_many_requests');
  }
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    const data = await response.json();
    throw new Error(data.Message);
  }
  throw new Error(response.statusText);
};

export default authFetch;
