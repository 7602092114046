import { accessTypes } from './actionTypes';

export const showAccessWall = (accessWall) => ({
  type: accessTypes.SHOW_ACCESS_WALL,
  payload: { accessWall },
});

export const setDcData = (dcData) => ({
  type: accessTypes.SET_DATA_COLLECTION_DATA,
  payload: { dcData },
});

export const hideAccessWall = () => ({
  type: accessTypes.HIDE_ACCESS_WALL,
});

export const setUseGift = (useGift) => ({
  type: accessTypes.SET_DATA_USE_GIFT,
  payload: { useGift },
});

export const setRefreshedTokens = (data) => ({
  type: accessTypes.SET_REFRESHED_TOKENS,
  payload: { data },
});

export const setShowArticle = (showArticle) => ({
  type: accessTypes.SET_DATA_SHOW_ARTICLE,
  payload: { showArticle },
});

export const setUserDataLoading = (isUserDataLoading) => ({
  type: accessTypes.SET_USER_DATA_LOADING,
  payload: { isUserDataLoading },
});

export const setIsInit = (isInit) => ({
  type: accessTypes.SET_IS_INIT,
  payload: { isInit },
});

export const setCompletionPercentage = (completionPercentage) => ({
  type: accessTypes.SET_COMPLETION_PERCENTAGE,
  payload: { completionPercentage },
});

export const setTargetingArgs = (targetingArguments) => ({
  type: accessTypes.SET_TARGETING_ARGS,
  payload: { targetingArguments },
});
