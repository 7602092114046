import { getMatchAction, isGoal, isOwnGoal } from '../helpers/matchFacts';
import { getMatchDate } from '../helpers/utils';
import { endpoints } from '../api';
import authFetch from '../helpers/authRequest';
import * as liveActions from '../actions/liveActions';
import * as accessActions from '../actions/accessActions';
import { getRankings } from './rankings';
import { getActions, getMatchLiveScore, getMatchSquads } from './matches';

export const getLives = (parameters, type, liveType) => async (dispatch, getState) => {
  try {
    console.log('getLives', parameters, type, liveType);
    const { date } = getState().entities.live;
    // eslint-disable-next-line max-len
    // const res = await dispatch(authFetch(`${endpoints.getLives}/${parameters.championship}/${parameters.division}?matchesDate=${date}`, {
    //   method: 'GET',
    // }));
    const res = await dispatch(authFetch(`${endpoints.getLives}?matchesDate=${date}`, {
      method: 'POST',
      body: JSON.stringify(parameters),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }));
    const data = await res.json();
    if (!res.ok) throw data;
    const matchesPromises = data.map(async (d) => {
      const isLive = d.isStarted && !d.isFinished;
      // Fetch the live score
      let score = false;
      if (isLive) {
        score = await dispatch(getMatchLiveScore(d.id));
      }
      return {
        ...d,
        minute: score ? score.minute : '',
        teamAway: { ...d.teamAway, goals: score ? score.awayGoals : d.teamAway.goals },
        teamHome: { ...d.teamHome, goals: score ? score.homeGoals : d.teamHome.goals },
        isLive,
      };
    });
    const matches = await Promise.all(matchesPromises);
    // Here the type is used when liveType !== 'multilive' or 'search'
    // And type is = 'user' or 'redac'
    dispatch(liveActions.setMatches(matches, type, liveType));
    return matches;
  } catch (error) {
    return [];
  }
};

export const getMatchesFacts = (skipLoading = false) => async (dispatch, getState) => {
  try {
    if (!skipLoading) dispatch(liveActions.setIsLoading(true));
    const { multiLive } = getState().entities.live;
    if (multiLive.matches.length) {
      const factsCalls = multiLive.matches.map(async (m) => {
        const squad = await dispatch(getMatchSquads(m.id));
        const actions = await dispatch(getActions(m.id));
        const importantActions = actions
          .filter((a) => getMatchAction(a.name, m.dataSourceName).display);
        const goals = importantActions.filter((g) => isGoal(g));
        const homeTeamGoals = goals
          .filter((g) => {
            if (isOwnGoal(g)) {
              return squad.visitorTeam.players.find((e) => e.name === g.person);
            }
            return squad.homeTeam.players.find((e) => e.name === g.person);
          });
        const awayTeamGoals = goals
          .filter((g) => {
            if (isOwnGoal(g)) {
              return squad.homeTeam.players.find((e) => e.name === g.person);
            }
            return squad.visitorTeam.players.find((e) => e.name === g.person);
          });
        return importantActions.map((a) => ({
          ...a,
          dateTime: getMatchDate(a.dateTime, m.dataSourceName).toDate(),
          goals,
          homeTeamGoals,
          awayTeamGoals,
          match: { ...m, squad },
        }));
      });
      const facts = await Promise.all(factsCalls);
      const sortedActions = facts.flat().sort((a, b) => a.dateTime - b.dateTime);
      dispatch(liveActions.setActions(sortedActions));
      if (sortedActions.length > 0) {
        dispatch(liveActions.setMultiLiveDate(
          getMatchDate(sortedActions[0].dateTime, sortedActions[0].match.dataSourceName),
        ));
      }
    } else {
      dispatch(liveActions.setActions([]));
    }
    if (!skipLoading) dispatch(liveActions.setIsLoading(false));
  } catch (error) {
    if (error.message === 'need_sub') {
      dispatch(accessActions.showAccessWall({
        type: error.message,
        strategy: 'modal',
        title: 'accéder aux faits de match ',
        target: '/live',
      }));
      return;
    }
    if (error.message === 'need_auth') {
      dispatch(accessActions.showAccessWall({
        type: error.message,
        strategy: 'modal',
        title: 'accéder aux faits de match ',
        target: '/live',
      }));
      return;
    }
    dispatch(liveActions.setIsLoading(false));
  }
};

export const changeSelectedCompetition = (competition, from = false) => (dispatch, getState) => {
  dispatch(liveActions.setSelectedCompetition(competition));
  const { search, user, redac } = getState().entities.live;
  if (from) {
    switch (from) {
      case 'search':
        dispatch(liveActions.setMatches(search.matches, 'multiLive', 'multiLive'));
        break;
      case 'user':
        dispatch(liveActions.setMatches(user.matches[competition.division], 'multiLive', 'multiLive'));
        break;
      case 'redac':
        dispatch(liveActions.setMatches(redac.matches[competition.division], 'multiLive', 'multiLive'));
        break;
      default:
        break;
    }
    if (from && getState().entities.live[from].isLive) {
      dispatch(liveActions.setLiveCompetitions(getState().entities.live[from].liveCompetitions, 'multiLive'));
      dispatch(liveActions.setIsLive(true, 'multiLive'));
    }
    dispatch(liveActions.setLiveFrom(from));
  }
  dispatch(getMatchesFacts(false, competition));
};

export const organizeMyRankings = (competitions, type) => (dispatch) => {
  dispatch(liveActions.setCompetitions(competitions, type));
  if (localStorage) {
    localStorage.setItem(`${type}Competitions`, JSON.stringify(competitions));
  }
};

const getRankingsByType = async (dispatch, type, userCompetition = []) => {
  try {
    dispatch(liveActions.setLiveTypeIsLoading(type, true));
    const data = await dispatch(getRankings(`/${type === 'user' ? 'MyRankings' : 'redaction'}`));
    let filteredRankings = data.filter((r) => r.sport === 'Football');
    if (type === 'redac') {
      filteredRankings = filteredRankings
        .filter((r) => userCompetition.find((uc) => uc.poolId === r.poolId) === undefined);
    }
    let lsCompet = localStorage ? localStorage.getItem(`${type}Competitions`) : null;
    if (lsCompet !== null) {
      lsCompet = JSON.parse(lsCompet);
      if (lsCompet.length) {
        const newLs = lsCompet
          .map((c) => filteredRankings.find((r) => r === c))
          .filter((c) => c !== undefined);
        filteredRankings.forEach((r) => {
          // Push the missing rankings if needed
          if (newLs.find((c) => c === r) === undefined) {
            newLs.push(r);
          }
        });
        dispatch(organizeMyRankings(newLs, type));
      } else {
        dispatch(organizeMyRankings(filteredRankings, type));
      }
    } else {
      dispatch(organizeMyRankings(filteredRankings, type));
    }
    const matchesCalls = filteredRankings.map(async (l) => (
      dispatch(getLives(l.topicId, l.division, type))
    ));
    const matches = await Promise.all(matchesCalls);
    const liveCompetitions = [];
    filteredRankings.forEach((l, i) => {
      matches[i].forEach((d) => {
        if (
          d.isStarted
          && !d.isFinished
          && liveCompetitions.find((lc) => lc.topicId === l.topicId) === undefined
        ) {
          liveCompetitions.push({ type: l.division, topicId: l.topicId });
        }
      });
    });
    dispatch(liveActions.setLiveCompetitions(liveCompetitions, type));
    dispatch(liveActions.setIsLive(liveCompetitions.length > 0, type));
    dispatch(liveActions.setLiveTypeIsLoading(type, false));
  } catch (error) {
    // Do not throw if need_auth
    dispatch(liveActions.setLiveTypeIsLoading(type, false));
  }
};

export const initLivePage = (fromMulti = false, isIframe = false) => async (dispatch, getState) => {
  dispatch(liveActions.setIsLoading(true));
  try {
    if (!isIframe) await getRankingsByType(dispatch, 'user');
    const { user } = getState().entities.live;
    if (!isIframe) await getRankingsByType(dispatch, 'redac', user.competitions);
    // dispatch(liveActions.sortMatches());
    dispatch(liveActions.setIsInit(true));
    if (!fromMulti) dispatch(liveActions.setIsLoading(false));
  } catch (error) {
    dispatch(liveActions.setIsLoading(false));
    if (error.message === 'need_auth' || error.message === 'need_sub') {
      dispatch(accessActions.showAccessWall({
        type: error.message,
        strategy: 'page',
        title: 'accéder aux lives',
      }));
    }
  }
};

export const initMultiLivePage = (
  competition,
  from = false,
  newDate = null,
  isIframe = false,
) => async (
  dispatch,
  getState,
) => {
  try {
    console.log('competition', competition);
    const { isInit } = getState().entities.live;
    dispatch(liveActions.setIsLoading(true));
    if (!isInit || newDate !== null) {
      await dispatch(initLivePage(true, isIframe));
      if (from === 'search') {
        dispatch(liveActions.setSearchCompetition(competition));
        dispatch(liveActions.setLiveFrom(from));
      }
    }
    const { user, redac } = getState().entities.live;
    let nextFrom;
    if (!from) {
      nextFrom = user.competitions.find((c) => c.topicId.toString() === competition.topic) !== undefined ? 'user' : undefined;
      if (nextFrom === undefined) {
        nextFrom = redac.competitions.find((c) => c.topicId.toString() === competition.topic) !== undefined ? 'redac' : undefined;
      }
      dispatch(liveActions.setLiveFrom(nextFrom));
    } else {
      dispatch(liveActions.setLiveFrom(from));
    }
    console.log('competition', competition);
    dispatch(liveActions.setSelectedCompetition(competition));
    const matches = await dispatch(getLives(competition, 'matches', 'multiLive'));
    console.log('matches call', matches);
    const liveCompetitions = [];
    matches.forEach((d) => {
      if (
        d.isStarted
        && !d.isFinished
        && liveCompetitions.find((lc) => lc.topicId === competition.topic) === undefined
      ) {
        liveCompetitions.push({ type: 'multiLive', topicId: competition.topic });
      }
    });
    dispatch(liveActions.setLiveCompetitions(liveCompetitions, 'multiLive'));
    dispatch(liveActions.setIsLive(liveCompetitions.length > 0, 'multiLive'));
    await dispatch(getMatchesFacts(true, competition.topic));
    dispatch(liveActions.setIsLoading(false));
  } catch (error) {
    dispatch(liveActions.setIsLoading(false));
  }
};

export const selectSearchCompetition = (competition) => async (dispatch) => {
  dispatch(liveActions.setLiveTypeIsLoading('search', true));
  try {
    dispatch(liveActions.setSearchCompetition(competition));
    dispatch(getLives(competition.topic_id, 'matches', 'search'));
    dispatch(liveActions.setLiveTypeIsLoading('search', false));
  } catch (error) {
    dispatch(liveActions.setSearchCompetition(undefined));
    dispatch(liveActions.setLiveTypeIsLoading('search', false));
  }
};
