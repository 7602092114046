import { endpoints } from '../api';
import authFetch from '../helpers/authRequest';

export const getActions = (matchId) => async (dispatch) => {
  try {
    const res = await dispatch(authFetch(`${endpoints.getMatchActions}?matchId=${matchId}`, {
      method: 'GET',
    }));
    const data = await res.json();
    if (!res.ok) throw data;
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getMatchLiveScore = (matchId) => async (dispatch) => {
  const res = await dispatch(authFetch(endpoints.getMatchLiveScore(matchId)));
  return res.json();
};

// export const getMatches = (from, to) => async (dispatch) => {
//   const res = await dispatch(authFetch(`${endpoints.getMatches}?from=${from}&to=${to}`, {
//     method: 'GET',
//     headers: {
//       'Content-type': 'application/json; charset=UTF-8',
//     },
//   }));
//   const data = await res.json();
//   if (!res.ok) throw data;
//   return data;
// };
export const getMatch = (matchId) => async (dispatch) => {
  const res = await dispatch(authFetch(`${endpoints.getMatches}/${matchId}`, {
    method: 'GET',
  }));
  const data = await res.json();
  if (!res.ok) throw data;
  return data;
};

export const getMatches = (from, to) => async (dispatch) => {
  const res = await dispatch(authFetch(`${endpoints.getMatches}?from=${from}&to=${to}`, {
    method: 'GET',
  }));
  const data = await res.json();
  if (!res.ok) throw data;
  return data;
};

export const getTeamMatches = (topicId, division, from, to, orderBy = 'asc') => async (dispatch) => {
  const res = await dispatch(authFetch(`${endpoints.getTeamMatches + topicId}?from=${from}&to=${to}&division=${division}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  }));
  const data = await res.json();
  if (!res.ok) throw data;
  return data.sort((a, b) => (
    orderBy === 'asc'
      ? new Date(a.match.matchDate) - new Date(b.match.matchDate)
      : new Date(b.match.matchDate) - new Date(a.match.matchDate)
  ));
};

export const getDivisionRanking = (topicId, division, type) => async (dispatch) => {
  const res = await dispatch(authFetch(`${endpoints.getDivisionRanking + topicId}?sportResultType=${type}${division ? `&division=${division}` : ''}`, {
    method: 'GET',
  }));
  const data = await res.json();
  if (!res.ok) throw data;
  return data;
};

export const getMatchSquads = (id) => async (dispatch) => {
  const res = await dispatch(authFetch(`${endpoints.getMatchSquads}?matchId=${id}`, {
    method: 'GET',
  }));
  const data = await res.json();
  if (!res.ok) throw data;
  return data;
};
