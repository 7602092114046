import { useEffect } from 'react';

const ScrollWrapper = ({ children, onScrollHandler }) => {
  const handleScroll = (e) => {
    onScrollHandler(e);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });
  return children;
};

export default ScrollWrapper;
