import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  const HOC = class extends Component {
    componentDidMount() {
      const { location } = this.props;
      const page = location.pathname;
      if (process.env.REACT_APP_URL === 'https://abosport.lavenir.net') {
        trackPage(page);
        localStorage.setItem('prevUrl', window.location.origin + location.pathname);
      }
    }

    componentDidUpdate(prevProps) {
      const { location } = this.props;
      const currentPage = location.pathname;
      const prevPage = prevProps.location.pathname;
      if (currentPage !== prevPage) {
        trackPage(currentPage);
        localStorage.setItem('prevUrl', window.location.origin + prevProps.location.pathname);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  HOC.propTypes = {
    location: PropTypes.object.isRequired,
  };

  return HOC;
}
