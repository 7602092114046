import React from 'react';
import PropTypes from 'prop-types';
import { isOwnGoal } from '../../helpers/matchFacts';
import s from './style.module.scss';

const Item = ({ game, goals }) => {
  const { isStarted, isFinished } = game;
  const teamHome = game.teamHome.name;
  const goalsHome = game.teamHome.goals;
  const teamAway = game.teamAway.name;
  const goalsAway = game.teamAway.goals;
  const displayTime = (!isStarted || isFinished) ? '-' : game.minute;
  return (
    <div className={s.item}>
      <div
        className={s.item__header}
        role="presentation"
      >
        <div className={s.item__header__team}>
          <div className={s.item__header__team__name}>
            {teamHome}
          </div>
          <div className={s.item__header__team__score}>
            {goalsHome}
          </div>
        </div>
        <div className={s.item__header__time}>
          {displayTime}
        </div>
        <div className={s.item__header__team}>
          <div className={s.item__header__team__score}>
            {goalsAway}
          </div>
          <div className={s.item__header__team__name}>
            {teamAway}
          </div>
        </div>
      </div>
      {goals !== undefined && (
      <div className={s.item__body}>
        <ul
          className={s.item__body__goals}
          role="presentation"
        >
          {goals.homeTeamGoals.map((g) => (
            <li key={g.actionId}>
              <span>
                {g.person}
                {' '}
                {isOwnGoal(g) && '(contre son camp)'}
              </span>
              <b>{`${g.actionTime}'`}</b>
            </li>
          ))}
        </ul>
        {/* {isFinished ? (
          <MdTimerOff />
        ) : (
          <div
            className={s.item__body__alert}
            onClick={() => setAlert(!alert)}
            onKeyPress={() => setAlert(!alert)}
            role="presentation"
          >
            {alert ? <FaBellSlash /> : <FiBell />}
          </div>
        )} */}
        <ul
          className={s.item__body__goals}
          role="presentation"
        >
          {goals !== undefined && goals.awayTeamGoals.map((g) => (
            <li key={g.actionId}>
              <span>{g.person}</span>
              <b>{`${g.actionTime}'`}</b>
            </li>
          ))}
        </ul>
      </div>
      )}
    </div>
  );
};

Item.propTypes = {
  game: PropTypes.any,
  goals: PropTypes.any,
};

Item.defaultProps = {
  game: undefined,
  goals: undefined,
};

export default Item;
