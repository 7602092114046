import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './style.module.scss';

const Status = ({ match }) => {
  const [status, setStatus] = useState('next');
  const { isStarted, isFinished } = match;

  function getStatus() {
    if (isStarted && !isFinished) {
      setStatus('now');
    } if (!isStarted && !isFinished) {
      setStatus('next');
    } if (isFinished) {
      setStatus('past');
    } return null;
  }

  useEffect(() => {
    getStatus(match.matchDate);
  }, [match.matchDate]);

  if (status === 'now') {
    return (
      <div className={classNames(s.container, s.container__now)}>
        en cours
      </div>
    );
  } if (status === 'past') {
    return (
      <div className={classNames(s.container, s.container__past)}>
        terminé
      </div>
    );
  }
  return (
    <div className={classNames(s.container, s.container__next)}>
      à venir
    </div>
  );
};

Status.propTypes = {
  match: PropTypes.any,
};

Status.defaultProps = {
  match: undefined,
};

export default Status;
