import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FiXCircle } from 'react-icons/fi';
import s from './style.module.scss';

const ModalVideo = ({ open, close, children }) => {
  useEffect(() => {
    document.onkeydown = (e) => {
      // eslint-disable-next-line no-param-reassign
      e = e || window.event;
      if (e.keyCode === 27) {
        close();
      }
    };
  });
  return (
    <div className={open ? s.open_container : s.close_container}>
      <div
        className={open ? s.open_container__closeArea : s.close_container__closeArea}
        onClick={close}
        onKeyPress={close}
        role="presentation"
      />
      <div
        className={s.open_container__closeButton}
        onClick={close}
        onKeyPress={close}
        role="presentation"
      >
        <FiXCircle />
      </div>
      <div
        className={open ? s.open_container__content : s.close_container__content}
        onClick={close}
        onKeyPress={close}
        role="presentation"
      >
        {children}
      </div>
    </div>
  );
};

ModalVideo.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  children: PropTypes.shape({}),
};

ModalVideo.defaultProps = {
  open: false,
  close: undefined,
  children: {},
};

export default ModalVideo;
