/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  getMatchAction, isGoal, isKickOfFact, isEndOfPeriodFact, isOwnGoal,
} from '../../../helpers/matchFacts';
import { stringSimilarity, getMatchDate } from '../../../helpers/utils';
import s from './style.module.scss';

const CardFact = ({
  action, match, teamAwayColor, teamHomeColor, goals, isMultiLive, squad,
}) => {
  const teamAway = match.teamAway.name;
  const teamHome = match.teamHome.name;

  function teamColor() {
    if (stringSimilarity(action.team, teamAway) > 0.45) {
      return teamAwayColor;
    } if (stringSimilarity(action.team, teamHome) > 0.45) {
      return teamHomeColor;
    } return null;
  }

  const getScoreByFactTime = (time, highlightTeam = false) => {
    const home = match.teamHome.name;
    const away = match.teamAway.name;
    let side;
    const scored = goals.filter((g) => g.actionTime <= time);
    if (scored.length === 0) {
      return `${home} 0 - 0 ${away}`;
    }
    let homeScore = 0;
    let awayScore = 0;
    scored.forEach((b) => {
      if (squad !== null && squad.homeTeam.players.find((e) => e.name === b.person)) {
        if (isOwnGoal(b)) {
          awayScore++;
          side = 'away';
        } else {
          homeScore++;
          side = 'home';
        }
      }
      if (squad !== null && squad.visitorTeam.players.find((e) => e.name === b.person)) {
        if (isOwnGoal(b)) {
          homeScore++;
          side = 'home';
        } else {
          awayScore++;
          side = 'away';
        }
      }
    });
    return (
      <>
        <span className={highlightTeam && side === 'home' ? s.high__header__score__team : ''}>
          {`${teamHome} ${homeScore} -`}
        </span>
        <span className={highlightTeam && side === 'away' ? s.high__header__score__team : ''}>
          {` ${awayScore} ${teamAway}`}
        </span>
      </>
    );
  };

  const types = (type) => {
    switch (type.hierarchy) {
      case 'low':
        if (action.actionTime === 0 || action.name !== "Coup d'envoi") {
          return (
            <div className={s.low}>
              <p>{type.icon}</p>
              <p className={isMultiLive ? s.multiLiveScore__name : s.low__name}>
                <span>{type.name}</span>
              </p>
              {isKickOfFact(action) && (
              <p>
                {getMatchDate(action.dateTime, match.dataSourceName).format(isMultiLive ? 'H[h]mm' : 'LLL')}
              </p>
              )}
              {action.actionTime > 0 && (
              <p>
                {action.actionTime}
                &apos;
              </p>
              )}
              {isEndOfPeriodFact(action) && (
              <p className={isMultiLive ? s.multiLiveScore : undefined}>
                {getScoreByFactTime(action.actionTime, isGoal(action))}
              </p>
              )}
              {isKickOfFact(action) && (
              <p className={isMultiLive ? s.multiLiveScore : undefined}>
                {`${teamHome} - ${teamAway}`}
              </p>
              )}
            </div>
          );
        } return null;
      case 'medium':
        return (
          <>
            <div className={s.medium}>
              <div className={s.medium__header}>
                <div className={s.medium__header__name}>
                  <span style={{ color: type.color !== '#000' && type.color }}>
                    {type.icon}
                  </span>
                  {type.name}
                </div>
                <div className={s.medium__header__time}>
                  {action.actionTime}
                  &apos;
                </div>
              </div>
              {action.person && (
              <div className={s.medium__header__info}>
                {((action.name === 'Remplacement' || action.name === 'REPLACEMENT') && action.personOut)
                  ? `${action.personOut} par ${action.person} - `
                  : `${action.person} - `}
                <span>{action.team}</span>
              </div>
              )}
              {(action.image !== null) && (
                <div className={s.medium__body}>
                  <img src={action.image} alt={action.comment} />
                </div>
              )}
              {action.comment && (
              <div className={s.medium__header__info}>
                {action.comment}
              </div>
              )}
              {(action.video !== null) && (
              // eslint-disable-next-line react/no-danger
              <div className={s.medium__body} dangerouslySetInnerHTML={{ __html: action.video }} />
              )}
            </div>
            {isMultiLive && (
              <p className={s.multiLiveScore}>{getScoreByFactTime(action.actionTime)}</p>
            )}
          </>
        );

      default:
        return (
          <>
            <div className={s.high}>
              <div
                className={classNames(
                  s.high__header,
                  action.video || action.image ? s.high__withImages : null,
                )}
                style={{ background: teamColor() !== '#000' && teamColor() }}
              >
                {type.icon}
                <div className={s.high__header__name}>
                  {type.name}
                </div>
                <div className={s.high__header__info}>
                  {`${action.person} (${action.actionTime}')`}
                </div>
                {(action.image !== null) && (
                  <div className={s.medium__body}>
                    <img src={action.image} alt={action.comment} />
                  </div>
                )}
                {action.comment && (
                <div className={s.medium__header__info}>
                  {action.comment}
                </div>
                )}
                {action.person && isGoal(action) && !isMultiLive && (
                <div className={s.high__header__score}>
                  {getScoreByFactTime(action.actionTime, isGoal(action))}
                </div>
                )}
              </div>
              {(action.video !== null) && (
              // eslint-disable-next-line react/no-danger
              <div className={s.high__body} dangerouslySetInnerHTML={{ __html: action.video }} />
              )}
            </div>
            {isMultiLive && (
              <p className={s.multiLiveScore}>
                {getScoreByFactTime(action.actionTime, isGoal(action))}
              </p>
            )}
          </>
        );
    }
  };

  return types(getMatchAction(action.name, match.dataSourceName));
};

CardFact.propTypes = {
  action: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
  teamAwayColor: PropTypes.string.isRequired,
  teamHomeColor: PropTypes.string.isRequired,
  goals: PropTypes.array.isRequired,
  squad: PropTypes.any.isRequired,
  isMultiLive: PropTypes.bool,
};

CardFact.defaultProps = {
  isMultiLive: false,
};

const mapStateToProps = (state) => {
  const { match } = state.entities;
  return {
    teamHomeColor: match.match.teamHomeColor,
    teamAwayColor: match.match.teamAwayColor,
  };
};

export default connect(
  mapStateToProps,
  null,
)(CardFact);
