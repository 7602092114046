/* eslint-disable */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { MdTimer, MdDateRange } from 'react-icons/md';
import Calendar from 'react-calendar';
import moment from 'moment';
import s from './style.module.scss';

const ScrollCalendar = ({ className, actions, currentDay, showLives, lives }) => {
  const [displayCalendar, setDisplayCalendar] = useState(false);

  const daysFuture = [...Array(4).keys()];
  const daysPast = [...Array(4).keys()];
  const parsedDate = (d, type, format) => moment()[type](d, 'days').format(format).replace('.', '');

  const getFuture = (
    daysFuture.slice(1).map((d) => {
      return (
        <li
          key={parsedDate(d, 'add', 'x')}
          onClick={() => actions ? actions.setDate(parsedDate(d, 'add', 'YYYY-MM-DD')) : undefined}
          onKeyPress={() => actions ? actions.setDate(parsedDate(d, 'add', 'YYYY-MM-DD')) : undefined}
          className={currentDay === parsedDate(d, 'add', 'YYYY-MM-DD') ? s.container__active : null}
          role="presentation"
        >
          <span>
            {parsedDate(d, 'add', 'ddd')}
          </span>
          <span>{parsedDate(d, 'add', 'D') }</span>
        </li>
      );
    })
  );

  const getPast = (
    daysPast.slice(1).reverse().map((d) => {
      return (
        <li
          key={parsedDate(d, 'subtract', 'x')}
          onClick={() => actions ? actions.setDate(parsedDate(d, 'subtract', 'YYYY-MM-DD')) : undefined}
          onKeyPress={() => actions ? actions.setDate(parsedDate(d, 'subtract', 'YYYY-MM-DD')) : undefined}
          className={currentDay === parsedDate(d, 'subtract', 'YYYY-MM-DD') ? s.container__active : null}
          role="presentation"
        >
          <span>
            {parsedDate(d, 'subtract', 'ddd')}
          </span>
          <span>{parsedDate(d, 'subtract', 'D')}</span>
        </li>
      );
    })
  );

  const getToday = (
    <li
      onClick={() => actions ? actions.setDate(moment().format('YYYY-MM-DD')) : undefined}
      onKeyPress={() => actions ? actions.setDate(moment().format('YYYY-MM-DD')) : undefined}
      role="presentation"
      className={classNames('calendarToday', currentDay === moment().format('YYYY-MM-DD') && s.container__active)}
    >
      <span>{moment().format('ddd').replace('.', '')}</span>
      <span>{moment().format('D')}</span>
    </li>
  );

  useEffect(() => {
    const container = document.querySelector('.calendar');

    if (container !== null) {
      container.scrollLeft = 0;
    }
  }, []);

  function handleChangeDate(d){
    actions.setDate(moment(d).format('YYYY-MM-DD'));
    setDisplayCalendar(false);
  }

  function calendarActive(){
    if(currentDay === moment().format('YYYY-MM-DD')
      || currentDay === moment().add(1, 'day').format('YYYY-MM-DD')
      || currentDay === moment().add(2, 'day').format('YYYY-MM-DD')
      || currentDay === moment().add(3, 'day').format('YYYY-MM-DD')
      || currentDay === moment().subtract(1, 'day').format('YYYY-MM-DD')
      || currentDay === moment().subtract(2, 'day').format('YYYY-MM-DD')
      || currentDay === moment().subtract(3, 'day').format('YYYY-MM-DD')
      ){
      return false;
    } return true;
  }

  return (
    <>
    <ul className={classNames(s.container, className, 'calendar')}>
      {showLives !== undefined && (
        <li 
          className={classNames(s.container__live, 
          lives ? undefined : s.container__liveDisable)}
          onClick={showLives}
          onKeyPress={showLives}
          role="presentation"
        >
          <span><MdTimer/></span>
          <span>Live</span>
        </li>
      )}
      {getPast}
      {getToday}
      {getFuture}
      <li className={classNames(s.container__calendar, displayCalendar || calendarActive() ? s.container__active : undefined)}>
        <MdDateRange 
          onClick={() => actions ? setDisplayCalendar(!displayCalendar) : undefined}
          onKeyPress={() => actions ? setDisplayCalendar(!displayCalendar) : undefined}
          role="presentation"
        />
        {calendarActive() && (
         <span>{moment(currentDay).format('DD-MM')}</span>
        )}
      </li>
    </ul>
    {displayCalendar && (
    <Calendar
      onChange={(d) => handleChangeDate(d)}
      defaultValue={moment(currentDay).toDate()}
      maxDetail="month"
      minDetail="month"
    />
    )}
    </>
  );
};

export default ScrollCalendar;
