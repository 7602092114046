export const userTypes = {
  SET_USER_NOTIFICATIONS_COUNT: 'SET_USER_NOTIFICATIONS_COUNT',
  SET_USER_TOPICS: 'SET_USER_TOPICS',
  SET_USER_BOOKMARKS: 'SET_USER_BOOKMARKS',
  SET_USER_REGION: 'SET_USER_REGION',
  SET_USER_FOLLOWED_MATCHES: 'SET_USER_FOLLOWED_MATCHES',
  SET_USER_FOLLOWED_TEAMS: 'SET_USER_FOLLOWED_TEAMS',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_USER_ACCESS_ERROR: 'SET_USER_ACCESS_ERROR',
  LOGOUT: 'LOGOUT',
  SET_USER_REGIONS_LIST: 'SET_USER_REGIONS_LIST',
};

export const accessTypes = {
  SHOW_ACCESS_WALL: 'SHOW_ACCESS_WALL',
  HIDE_ACCESS_WALL: 'HIDE_ACCESS_WALL',
  SET_DATA_COLLECTION_DATA: 'SET_DATA_COLLECTION_DATA',
  SET_DATA_USE_GIFT: 'SET_DATA_USE_GIFT',
  SET_COMPLETION_PERCENTAGE: 'SET_COMPLETION_PERCENTAGE',
  SET_USER_DATA_LOADING: 'SET_USER_DATA_LOADING',
  SET_DATA_SHOW_ARTICLE: 'SET_DATA_SHOW_ARTICLE',
  SET_IS_INIT: 'SET_IS_INIT',
  SET_REFRESHED_TOKENS: 'SET_REFRESHED_TOKENS',
  SET_TARGETING_ARGS: 'SET_TARGETING_ARGS',
};

export const searchTypes = {
  SET_SEARCH_VAL: 'SET_SEARCH_VAL',
  SET_SEARCH_TOPICS: 'SET_SEARCH_TOPICS',
  SET_IS_SEARCHING: 'SET_IS_SEARCHING',
  RESET_SEARCH: 'RESET_SEARCH',
  SET_IS_FOCUSED: 'SET_IS_FOCUSED',
  SET_SEARCH_FILTERS: 'SET_SEARCH_FILTERS',
  SET_SHOW_SEARCH_FILTERS: 'SET_SHOW_SEARCH_FILTERS',
};

export const teamTypes = {
  RESET_CLUB: 'RESET_CLUB',
  SET_CLUB_COLOR: 'SET_CLUB_COLOR',
  SET_CLUB_ISLOADING: 'SET_CLUB_ISLOADING',
  SET_CLUB_TAB: 'SET_CLUB_TAB',
  SET_CLUB_ID: 'SET_CLUB_ID',
  SET_CLUB_MATCHES: 'SET_CLUB_MATCHES',
  SET_CLUB_MATCHES_LOADING: 'SET_CLUB_MATCHES_LOADING',
  SET_CLUB_COMPETITIONS: 'SET_CLUB_COMPETITIONS',
  SET_CLUB_COMPETITIONS_LOADING: 'SET_CLUB_COMPETITIONS_LOADING',
  SET_CLUB_SELECTED_DIVISION: 'SET_CLUB_SELECTED_DIVISION',
  SET_CLUB_RANKING: 'SET_CLUB_RANKING',
  SET_CLUB_CLUB: 'SET_CLUB_CLUB',
  SET_CLUB_TEAMS: 'SET_CLUB_TEAMS',
  SET_CLUB_RANKING_LOADING: 'SET_CLUB_RANKING_LOADING',
};

export const appTypes = {
  RESET_MATCH: 'RESET_MATCH',
  SET_MATCH_COLOR: 'SET_MATCH_COLOR',
  SET_HEADLINES_REGION: 'SET_HEADLINES_REGION',
  REFRESH_FEED: 'REFRESH_FEED',
  SET_FEED_LOADING: 'SET_FEED_LOADING',
  SET_FEED_NEXT_PAGES_LOADING: 'SET_FEED_NEXT_PAGES_LOADING',
  SET_FEED_ERROR: 'SET_FEED_ERROR',
  SET_FEED_HAS_NEXT_PAGE: 'SET_FEED_HAS_NEXT_PAGE',
  SET_FEED_ARTICLES: 'SET_FEED_ARTICLES',
  SET_FEED_PAGE: 'SET_FEED_PAGE',
  SET_SEARCH_TOPIC: 'SET_SEARCH_TOPIC',
  SET_SEARCH_ID: 'SET_SEARCH_ID',
  SET_NOTIFICATION_ID: 'SET_NOTIFICATION_ID',
  SET_HEADLINES: 'SET_HEADLINES',
  SET_FIXED_HEADER: 'SET_FIXED_HEADER',
  SET_APP_COLOR: 'SET_APP_COLOR',
  SET_APP_EMPTY_ADS: 'SET_APP_EMPTY_ADS',
  SET_APP_ACTIVE_SECTION: 'SET_APP_ACTIVE_SECTION',
  SET_APP_FEED_FORMULA: 'SET_APP_FEED_FORMULA',
  SET_APP_HIDE_LEADER: 'SET_APP_HIDE_LEADER',
  SET_APP_SECTIONS: 'SET_APP_SECTIONS',
};

export const leagueTypes = {
  RESET_LEAGUE: 'RESET_LEAGUE',
  SET_LEAGUE_DIVISION: 'SET_LEAGUE_DIVISION',
  SET_LEAGUE_ISLOADING: 'SET_LEAGUE_ISLOADING',
  SET_LEAGUE_TAB: 'SET_LEAGUE_TAB',
  SET_LEAGUE_ID: 'SET_LEAGUE_ID',
  SET_LEAGUE_PAST_MATCHES: 'SET_LEAGUE_PAST_MATCHES',
  SET_LEAGUE_PAST_MATCHES_LOADING: 'SET_LEAGUE_PAST_MATCHES_LOADING',
  SET_LEAGUE_FUTURE_MATCHES: 'SET_LEAGUE_FUTURE_MATCHES',
  SET_LEAGUE_FUTURE_MATCHES_LOADING: 'SET_LEAGUE_FUTURE_MATCHES_LOADING',
  SET_LEAGUE_RANKING: 'SET_LEAGUE_RANKING',
  SET_LEAGUE_RANKING_LOADING: 'SET_LEAGUE_RANKING_LOADING',
  SET_LEAGUE_RESULT: 'SET_LEAGUE_RESULT',
  SET_LEAGUE_RESULT_LOADING: 'SET_LEAGUE_RESULT_LOADING',
  SET_LEAGUE_ANN: 'SET_LEAGUE_ANN',
  SET_LEAGUE_ANN_LOADING: 'SET_LEAGUE_ANN_LOADING',
};

export const liveTypes = {
  RESET_LIVE: 'RESET_LIVE',
  SET_LIVE_DATE: 'SET_LIVE_DATE',
  SET_LIVE_CURRENT_DATE: 'SET_LIVE_CURRENT_DATE',
  SET_LIVE_MATCHES: 'SET_LIVE_MATCHES',
  SET_LIVE_COMPETITIONS: 'SET_LIVE_COMPETITIONS',
  SET_LIVE_SORT_MATCHES: 'SET_LIVE_SORT_MATCHES',
  SET_SELECTED_COMPETITION: 'SET_SELECTED_COMPETITION',
  SET_LIVE_ACTIONS: 'SET_LIVE_ACTIONS',
  SET_LIVE_LOADING: 'SET_LIVE_LOADING',
  SET_MULTI_LIVE_DATE: 'SET_MULTI_LIVE_DATE',
  SET_LIVE_IS_LIVE: 'SET_LIVE_IS_LIVE',
  SET_LIVE_LIVE_COMPETITIONS: 'SET_LIVE_LIVE_COMPETITIONS',
  SET_LIVE_TYPE_LOADING: 'SET_LIVE_TYPE_LOADING',
  RESET_LIVE_SEARCH: 'RESET_LIVE_SEARCH',
  SET_LIVE_SEARCH_COMPETITION: 'SET_LIVE_SEARCH_COMPETITION',
  SET_LIVE_FROM: 'SET_LIVE_FROM',
  SET_LIVE_ONLY: 'SET_LIVE_ONLY',
  SET_LIVE_IS_INIT: 'SET_LIVE_IS_INIT',
};
