/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';

import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import moment from 'moment';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';
import {
  createTheme, withStyles, makeStyles, ThemeProvider,
} from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { copyTextToClipboard, notifOptions } from '../../helpers/utils';
import { getMatches } from '../../services/matches';
import UserNotification from '../UserNotification';
import { setNotificationId } from '../../actions/appActions';
import Loading from '../Loading';

import s from './style.module.scss';
import 'moment/locale/fr';

moment.locale('fr');

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

const GreenCheckbox = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const PageCompetitions = ({ dispatch }) => {
  const url = process.env.REACT_APP_URL;
  const [checkedDivisions, setCheckedDivisions] = React.useState([]);
  const [checkedMatches, setCheckedMatches] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [competitions, setCompetitions] = useState([]);
  const [multiIframe, setMultiIframe] = useState(null);
  const from = moment(new Date()).add(-2, 'days').format('YYYY[-]MM[-]DD');
  const to = moment(new Date()).add(7, 'days').format('YYYY[-]MM[-]DD');
  const now = moment(new Date()).format('YYYY[-]MM[-]DD');

  const fetchData = async () => {
    try {
      const matchesRange = await dispatch(getMatches(from, to));
      setCompetitions(matchesRange);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const generateIframe = () => {
    if (checkedDivisions.length > 0 || checkedMatches.length > 0) {
      const iframe = `
      <iframe
        src="${url}/multilive?${checkedDivisions.length > 0 ? `divisions=${checkedDivisions.join()}&` : ''}${checkedMatches.length > 0 ? `matchs=${checkedMatches.join()}&` : ''}iframe=true&date=${now}"
        style="border:0px #ffffff none;"
        name="myiFrame"
        scrolling="yes"
        frameBorder="1"
        marginHeight="0px"
        marginWidth="0px"
        height="800px"
        width="100%"
        allowFullScreen
      ></iframe>
    `;
      setMultiIframe(iframe);
    } else {
      setMultiIframe('');
    }
  };

  const toggleIframe = (type, index, value) => {
    switch (type) {
      case 'competition':
        // eslint-disable-next-line no-case-declarations
        const newCompetitions = competitions.map((c, i) => (i === index ? ({ ...c, iframeOpen: value }) : ({ ...c })));
        setCompetitions([...newCompetitions]);
        break;
      case 'match':
        // eslint-disable-next-line no-case-declarations
        const newmatchs = competitions.map((c) => {
          c.divisions.map((d) => {
            const division = d;
            division.matchs = d.matchs.map((m) => (index === m.id ? ({ ...m, iframeOpen: value }) : ({ ...m })));
            return division;
          });
          return c;
        });
        setCompetitions([...newmatchs]);
        break;
      default:
        break;
    }
  };

  const handleToggleMatches = (value) => () => {
    const currentIndex = checkedMatches.indexOf(value);
    const newChecked = [...checkedMatches];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedMatches(newChecked);
  };

  const handleToggleDivisions = (value) => () => {
    const currentIndex = checkedDivisions.indexOf(value);
    const newChecked = [...checkedDivisions];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedDivisions(newChecked);
  };

  return (
    <>
      <div className={s.container}>
        <h1>Live Collector compétitions</h1>
        <p>
          Liste des lives suivis entre le
          {' '}
          {from}
          {' '}
          et le
          {' '}
          {to}
        </p>
        <div className={s.container__graph}>
          {competitions.length > 0 && (
            <ColorButton
              onClick={() => generateIframe()}
              text="Générer Iframe"
              color="primary"
              className={s.container__menu__multi}
            >
              Générer Iframe Multilive
            </ColorButton>
          )}
          {multiIframe && (
            <>
              <pre style={{ whiteSpace: 'pre-wrap' }}>{multiIframe}</pre>
              <ColorButton
                onClick={async () => {
                  try {
                    await copyTextToClipboard(multiIframe);
                    // setOpen(true);
                    dispatch(setNotificationId(store.addNotification({
                      content: <UserNotification text="Iframe saved to clipboard" />,
                      ...notifOptions,
                    })));
                  } catch (error) {
                    console.log(error);
                  }
                }}
                text="Copier iframe"
                className={s.container__menu__multi}
              >
                Copier iframe
              </ColorButton>
            </>
          )}
          {isLoading ? <Loading small /> : (
            <>
              {competitions.length === 0 && <p>Pas de matchs</p>}
              {competitions.length > 0 && competitions.map((c, i) => (
                <div key={c.id}>
                  <h3>{c.name}</h3>
                  <List dense component="div" role="list">
                    {c.divisions.map((d) => (
                      <div key={d.id}>
                        <ListItem key={d.id} role="listitem" button onClick={handleToggleDivisions(d.id)}>
                          <ListItemIcon>
                            <GreenCheckbox
                              checked={checkedDivisions.indexOf(d.id) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': d.name }}
                            />
                          </ListItemIcon>
                          <ListItemText id={d.name}>
                            {d.name}
                          </ListItemText>
                        </ListItem>
                        {/* <div>
                        Iframe multi-live:
                        {' '}
                        {c.iframeOpen ? <FiChevronUp onClick={() => toggleIframe('competition', i, !c.iframeOpen)} /> : <FiChevronDown onClick={() => toggleIframe('competition', i, !c.iframeOpen)} />}
                        {' '}
                        <Button
                          onClick={async () => {
                            try {
                              await copyTextToClipboard(`<iframe src="${url}/multilive/${c.id}/${encodeURI(d.id)}?iframe=true&date=${from}" style="border:0px #ffffff none;" name="myiFrame" scrolling="yes" frameBorder="1" marginHeight="0px" marginWidth="0px" height="800px" width="100%" allowFullScreen></iframe>`);
                              // setOpen(true);
                              dispatch(setNotificationId(store.addNotification({
                                content: <UserNotification text="Iframe copié" />,
                                ...notifOptions,
                              })));
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                          text="Copier iframe"
                          className={s.container__menu__multi}
                        >
                          Copier iframe
                        </Button>
                      </div>
                      <div className={c.iframeOpen ? s.container__graph__iframe__open : s.container__graph__iframe__closed}>
                        <pre>
                          {`
                          <iframe
                            src="${url}/multilive/${c.id}/${encodeURI(d.id)}?iframe=true&date=${from}"
                            style="border:0px #ffffff none;"
                            name="myiFrame"
                            scrolling="yes"
                            frameBorder="1"
                            marginHeight="0px"
                            marginWidth="0px"
                            height="800px"
                            width="100%"
                            allowFullScreen
                          ></iframe>
                        `}
                        </pre>
                      </div>
                      <p>
                        {"Attention: ne pas oublier de remplacer la date par celle désirée, ex pour aujourd'hui date="}
                        {from}
                        {' '}
                      </p> */}
                        <h5>Matches:</h5>
                        <List dense component="div" role="list">
                          {d.matchs.length && d.matchs.map((m) => (
                            <div className={s.container__graph__match}>
                              <ListItem key={m.id} role="listitem" button onClick={handleToggleMatches(m.id)}>
                                <ListItemIcon>
                                  <GreenCheckbox
                                    checked={checkedMatches.indexOf(m.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': m.teamHome.name }}
                                  />
                                </ListItemIcon>
                                <ListItemText id={m.id}>
                                  {moment(m.matchDate).format('LLL')}
                                  {' | '}
                                  {m.teamHome.name}
                                  {' '}
                                  -
                                  {' '}
                                  {m.teamAway.name}
                                </ListItemText>
                              </ListItem>
                              {/* <span>
                            {moment(m.matchDate).format('LLL')}
                            {' | '}
                            {m.teamHome.name}
                            {' '}
                            -
                            {' '}
                            {m.teamAway.name}
                          </span>
                          <br /> */}
                              Iframe:
                              {' '}
                              {m.iframeOpen ? <FiChevronUp onClick={() => toggleIframe('match', m.id, !m.iframeOpen)} /> : <FiChevronDown onClick={() => toggleIframe('match', m.id, !m.iframeOpen)} />}
                              {' '}
                              <ColorButton
                                onClick={async () => {
                                  try {
                                    await copyTextToClipboard(`<iframe src="${url}/live/${m.id}?iframe=true" style="border:0px #ffffff none;" name="myiFrame" scrolling="yes" frameBorder="1" marginHeight="0px" marginWidth="0px" height="800px" width="100%" allowFullScreen></iframe>`);
                                    // setOpen(true);
                                    dispatch(setNotificationId(store.addNotification({
                                      content: <UserNotification text="Iframe saved to clipboard" />,
                                      ...notifOptions,
                                    })));
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                                text="Copier iframe"
                                className={s.container__menu__multi}
                              >
                                Copier iframe
                              </ColorButton>
                              <div className={m.iframeOpen ? s.container__graph__iframe__open : s.container__graph__iframe__closed}>
                                <pre>
                                  {`
                            <iframe
                              src="${url}/live/${m.id}?iframe=true"
                              style="border:0px #ffffff none;"
                              name="myiFrame"
                              scrolling="yes"
                              frameBorder="1"
                              marginHeight="0px"
                              marginWidth="0px"
                              height="800px"
                              width="100%"
                              allowFullScreen
                            ></iframe>
                          `}
                                </pre>
                              </div>
                            </div>
                          ))}
                        </List>
                      </div>
                    ))}
                  </List>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Iframe copié
        </Alert>
      </Snackbar> */}
    </>
  );
};

PageCompetitions.defaultProps = {
  dispatch: null,
};

PageCompetitions.propTypes = {
  dispatch: PropTypes.any,
};

PageCompetitions.defaultProps = {
  dispatch: null,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  null,
  mapDispatchToProps,
)(PageCompetitions);
