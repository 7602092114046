import { appTypes } from './actionTypes';

export const setFeedLoading = (type, isLoading) => ({
  type: appTypes.SET_FEED_LOADING,
  payload: { type, isLoading },
});

export const setFeedNextPagesLoading = (type, isNextPagesLoading) => ({
  type: appTypes.SET_FEED_NEXT_PAGES_LOADING,
  payload: { type, isNextPagesLoading },
});

export const setFeedError = (type, error) => ({
  type: appTypes.SET_FEED_ERROR,
  payload: { type, error },
});

export const refreshFeed = (type) => ({
  type: appTypes.REFRESH_FEED,
  payload: { type },
});

export const setActiveSection = (activeSection) => ({
  type: appTypes.SET_APP_ACTIVE_SECTION,
  payload: { activeSection },
});

export const setSections = (sections) => ({
  type: appTypes.SET_APP_SECTIONS,
  payload: { sections },
});

export const hideLeader = () => ({
  type: appTypes.SET_APP_HIDE_LEADER,
});

export const setEmptyAds = (emptyAds) => ({
  type: appTypes.SET_APP_EMPTY_ADS,
  payload: { emptyAds },
});

export const setFeedArticles = (articles, type) => ({
  type: appTypes.SET_FEED_ARTICLES,
  payload: { articles, type },
});

export const setFeedPage = (page, type) => ({
  type: appTypes.SET_FEED_PAGE,
  payload: { page, type },
});

export const setFeedHasNextPage = (type, hasNextPage) => ({
  type: appTypes.SET_FEED_HAS_NEXT_PAGE,
  payload: { hasNextPage, type },
});

export const setSearchId = (searchId) => ({
  type: appTypes.SET_SEARCH_ID,
  payload: { searchId },
});

export const setSearchTopic = (searchTopic) => ({
  type: appTypes.SET_SEARCH_TOPIC,
  payload: { searchTopic },
});

export const setNotificationId = (notificationId) => ({
  type: appTypes.SET_NOTIFICATION_ID,
  payload: { notificationId },
});

export const resetMatch = () => ({
  type: appTypes.RESET_MATCH,
});

export const setMatchColor = (type, color) => ({
  type: appTypes.SET_MATCH_COLOR,
  payload: { type, color },
});

export const setHeadlines = (articles) => ({
  type: appTypes.SET_HEADLINES,
  payload: { articles },
});

export const setHeadlinesRegion = (region, type) => ({
  type: appTypes.SET_HEADLINES_REGION,
  payload: { region, type },
});

export const setFixedHeader = (fixedHeader) => ({
  type: appTypes.SET_FIXED_HEADER,
  payload: { fixedHeader },
});

export const setAppColor = (appColor) => ({
  type: appTypes.SET_APP_COLOR,
  payload: { appColor },
});
