import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiFrown } from 'react-icons/fi';
import s from './style.module.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError && process.env.REACT_APP_ENV === 'PRODUCTION') {
      // render fallback UI
      return (
        <div className={s.container}>
          <FiFrown />
          <p>
            Oups, une erreur est survenue...
            <br />
            Notre équipe va la fixer au plus vite
          </p>
        </div>
      );
    }

    // when there's not an error, render children untouched
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ErrorBoundary;
