import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDate } from '../../actions/liveActions';
import ScrollCalendar from './ScrollCalendar';

const mapStateToProps = (state) => {
  const { live } = state.entities;
  return {
    currentDay: live.date,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    setDate: bindActionCreators(setDate, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScrollCalendar);
