import React from 'react';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';
import s from './style.module.scss';

const UserNotification = ({
  text,
  warning,
}) => (
  <div
    className={s.container}
    style={{ backgroundColor: warning ? '#d60b13' : 'green' }}
  >
    {text}
    <span><FiX /></span>
  </div>
);

UserNotification.propTypes = {
  warning: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

UserNotification.defaultProps = {
  warning: false,
};

export default UserNotification;
