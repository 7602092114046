import moment from 'moment';
import { liveTypes, userTypes } from '../../actions/actionTypes';

const defaultLive = {
  matches: {},
  competitions: [],
  liveCompetitions: [],
  isLive: false,
  isLoading: false,
};

const defaultSearch = {
  matches: [],
  isLive: false,
  isLoading: false,
};

const defaultMultiLive = {
  matches: [],
  actions: [],
  isLive: false,
  isLoading: false,
};

const defaultState = {
  date: moment().format('YYYY-MM-DD'),
  liveDate: moment().fromNow(),
  user: { ...defaultLive },
  redac: { ...defaultLive },
  search: { ...defaultSearch },
  multiLive: { ...defaultMultiLive },
  isLoading: false,
  isInit: false,
  isLiveOnly: false,
};

// eslint-disable-next-line default-param-last
const live = (state = defaultState, action) => {
  switch (action.type) {
    case userTypes.LOGOUT:
      return { ...defaultState };
    case liveTypes.RESET_LIVE:
      return { ...defaultState };
    case userTypes.SET_USER_DATA:
      return { ...defaultState };
    case liveTypes.SET_LIVE_IS_INIT:
      return { ...state, isInit: action.payload.isInit };
    case liveTypes.SET_LIVE_SEARCH_COMPETITION:
      return { ...state, searchCompetition: action.payload.searchCompetition };
    case liveTypes.RESET_LIVE_SEARCH:
      return {
        ...state, search: { ...defaultSearch }, searchCompetition: undefined,
      };
    case liveTypes.RESET_MULTI_LIVE:
      return {
        ...state, multiLive: { ...defaultMultiLive }, selectedCompetition: undefined,
      };
    case liveTypes.SET_LIVE_ONLY:
      return { ...state, isLiveOnly: action.payload.isLiveOnly };
    case liveTypes.SET_LIVE_LOADING:
      return { ...state, isLoading: action.payload.isLoading };
    case liveTypes.SET_LIVE_TYPE_LOADING:
      return {
        ...state,
        [action.payload.liveType]: {
          ...state[action.payload.liveType],
          isLoading: action.payload.isLoading,
        },
      };
    case liveTypes.SET_LIVE_FROM:
      return { ...state, from: action.payload.from };
    case liveTypes.SET_LIVE_DATE:
      return { ...state, date: action.payload.date };
    case liveTypes.SET_MULTI_LIVE_DATE:
      return { ...state, liveDate: action.payload.liveDate };
    case liveTypes.SET_LIVE_IS_LIVE:
      return {
        ...state,
        [action.payload.liveType]: {
          ...state[action.payload.liveType],
          isLive: action.payload.isLive,
        },
      };
    case liveTypes.SET_LIVE_LIVE_COMPETITIONS:
      return {
        ...state,
        [action.payload.liveType]: {
          ...state[action.payload.liveType],
          liveCompetitions: action.payload.liveCompetitions,
        },
      };
    case liveTypes.SET_LIVE_CURRENT_DATE:
      return {
        ...state,
        currentDate: action.payload.currentDate,
        liveDate: moment(action.payload.currentDate).format('HH:mm'),
      };
    case liveTypes.SET_LIVE_ACTIONS:
      return {
        ...state,
        multiLive: {
          ...state.multiLive,
          actions: action.payload.actions,
        },
      };
    case liveTypes.SET_LIVE_MATCHES:
      return {
        ...state,
        [action.payload.liveType]: {
          ...state[action.payload.liveType],
          matches: action.payload.liveType === 'search' || action.payload.liveType === 'multiLive'
            ? action.payload.matches
            : {
              ...state[action.payload.liveType].matches,
              [action.payload.type]: action.payload.matches,
            },
        },
      };
    case liveTypes.SET_LIVE_COMPETITIONS: {
      const newState = {
        ...state,
        [action.payload.liveType]: {
          ...state[action.payload.liveType],
          competitions: action.payload.competitions,
        },
      };
      const currentMatches = newState[action.payload.liveType].matches;
      action.payload.competitions.forEach((c) => {
        currentMatches[c.division] = currentMatches[c.division] !== undefined
          ? currentMatches[c.division]
          : [];
      });
      return newState;
    }
    case liveTypes.SET_SELECTED_COMPETITION:
      return {
        ...state,
        selectedCompetition: action.payload.selectedCompetition,
        multiLive: { ...defaultMultiLive },
      };
    case liveTypes.SET_LIVE_SORT_MATCHES:
      // eslint-disable-next-line no-case-declarations
      const newState = {
        ...state,
        isInit: true,
        user: {
          ...state.user,
          competitions: state.user.competitions.sort((b, a) => (
            state.user.matches[a.division].length - state.user.matches[b.division].length
          )),
        },
        redac: {
          ...state.redac,
          competitions: state.redac.competitions.sort((b, a) => (
            state.redac.matches[a.division].length - state.redac.matches[b.division].length
          )),
        },
      };
      return newState;
    default:
      return state;
  }
};

export default live;
