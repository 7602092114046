const isInWebAppiOS = process.browser && (window.navigator.standalone === true);
const isInWebAppChrome = process.browser && (window.matchMedia('(display-mode: standalone)').matches);
let isInLavenirSportApp = process.browser && navigator.userAgent.indexOf('Lavenir Sport') !== -1;
if (process.env.REACT_APP_ENV === 'DEV' && !isInLavenirSportApp) {
  isInLavenirSportApp = process.browser && window.parent.LavenirSport !== undefined;
}
const isNative = isInWebAppiOS
  || isInWebAppChrome
  || isInLavenirSportApp
  || process.env.REACT_APP_ENV === 'DEV'
  || process.env.REACT_APP_ENV === 'TEST'
  || process.env.REACT_APP_ENV === 'PREVIEW';

export default isNative;
