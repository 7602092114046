import moment from 'moment-timezone';
import isNative from '../commons/checkPWA';

export const urlParam = (name) => {
  if (!process.browser) {
    return null;
  }
  const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(
    window.location.href,
  );
  if (results == null) {
    return null;
  }
  return decodeURI(results[1]) || null;
};

export const trimTolc = (string) => string.toLowerCase().split(' ').join('');

export const removeDuplicates = (arr, prop) => Object
  .values(arr.reduce((acc, cur) => Object.assign(
    acc,
    { [cur[prop]]: cur },
  ), {}));

export const notifOptions = {
  insert: isNative ? 'bottom' : 'top',
  container: isNative ? 'bottom-center' : 'top-center',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 2000,
    onScreen: false,
    pauseOnHover: false,
  },
};

const editDistance = (s1, s2) => {
  const string1 = s1.toLowerCase();
  const string2 = s2.toLowerCase();

  const costs = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= string1.length; i++) {
    let lastValue = i;
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j <= string2.length; j++) {
      if (i === 0) { costs[j] = j; } else if (j > 0) {
        let newValue = costs[j - 1];
        if (string1.charAt(i - 1) !== string2.charAt(j - 1)) {
          newValue = Math.min(
            Math.min(newValue, lastValue),
            costs[j],
          ) + 1;
        }
        costs[j - 1] = lastValue;
        lastValue = newValue;
      }
    }
    if (i > 0) { costs[string2.length] = lastValue; }
  }
  return costs[string2.length];
};

export const stringSimilarity = (s1, s2) => {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  const result = (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
  return result;
};

export const checkIsInIframe = () => (typeof window === 'undefined' ? false : window.location !== window.parent.location);

export const getMatchDate = (startDate, type) => {
  switch (type) {
    case 'Infostrada':
      return moment(startDate);
    case 'Livecollector':
      return moment(startDate);
    default:
      return moment(startDate);
  }
};

export function titleCase(str) {
  const splitStr = str.toLowerCase().split(' ');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export const randomUUid = () => ('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
  /[xy]/g,
  (c) => {
    const r = (Math.random() * 16) | 0; // eslint-disable-line no-bitwise
    const v = c === 'x' ? r : (r && 0x3) | 0x8; // eslint-disable-line no-bitwise
    return v.toString(16);
  },
));

export const b64ToUtf8 = (str, parse = false) => {
  const decoded = decodeURIComponent(escape(window.atob(str)));
  return parse ? JSON.parse(decoded) : decoded;
};

const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log(`Fallback: Copying text command was ${msg}`);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return null;
  }
  return navigator.clipboard.writeText(text);
};
