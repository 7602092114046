/* eslint-disable import/prefer-default-export */
const url = process.env.REACT_APP_API_URL;

const endpoints = {
  postSearchArticles: `${url}/Search/v2/result`,
  postHeadlineArticles: `${url}/Article/headlines`,
  postHeadlineIds: `${url}/Article/headline/ids`,
  getSingleArticle: (articleId) => `${url}/Article/${articleId}`,
  getLinkedArticles: `${url}/Article/linked`,
  getMatchDetails: `${url}/Article/match`,
  getUserTopics: `${url}/User/topic`,
  postUserTopics: `${url}/User/topic`,
  searchTopics: `${url}/Search/propositions`,
  getMatchActions: `${url}/Match/actions`,
  getMatchSquads: `${url}/Match/Squads`,
  userAuthenticate: `${url}/User/authenticate`,
  userAuthenticateCiam: `${url}/User/authenticate/ciam`,
  userIdentity: `${url}/User/identity`,
  userRegisterCiam: `${url}/User/registration/ciam`,
  userUpdate: `${url}/User`,
  logout: `${url}/User/connection`,
  postBookmarks: `${url}/User/bookmark/`,
  getBookmarks: (ids) => `${url}/User/bookmarks${ids ? '?IsIdsOnly=true' : ''}`,
  getSports: `${url}/Search/propositions?type=sport`,
  setUserTopicsSignIn: `${url}/User/new/topic`,
  postFeedback: `${url}/Feedback`,
  newPassword: `${url}/User/password`,
  getNotifications: `${url}/Notification/list`,
  seenNotification: (id) => `${url}/Notification/${id}/IsOpened`,
  notificationsUnread: `${url}/Notification/NumberAsUnread`,
  userPreferences: `${url}/User/preferences`,
  updateNotifPref: (topicId) => `${url}/User/${topicId}/Notification`,
  updateRegion: `${url}/User/preferedRegion`,
  getRankings: `${url}/Ranking`,
  getRankingsFilters: `${url}/Ranking/filters`,
  getFootRankingsFilters: `${url}/Ranking`,
  postRanking: `${url}/Ranking/`,
  getTeamCompetitions: `${url}/Search/competitions/`,
  getTeamMatches: `${url}/Search/matches/`,
  getDivisionRanking: `${url}/Search/SportResults/`,
  getLives: `${url}/Match/Live`,
  getMatches: `${url}/Match`,
  getClubTeams: (clubTopicId) => `${url}/Club/${clubTopicId}/teams`,
  getTeamClub: `${url}/Club`,
  getClubPlayer: (id) => `${url}/Player/${id}/teamTopics`,
  postDataCollect: (id) => `${url}/User/datacollect?questionId=${id}`,
  verifyEmail: `${url}/User/verifyEmail`,
  validateEmail: `${url}/User/validateEmail`,
  resendValidationCode: `${url}/User/ResendMailConfirm`,
  watchedCommercialVideo: `${url}/User/commercial`,
  useWelcomeGift: `${url}/User/welcomegift`,
  getArticlesLeft: `${url}/User/giftedarticle/left`,
  refreshToken: `${url}/User/refreshToken`,
  zipSuggestions: `${url}/Region/city`,
  usersAnalytics: `${url}/Analytics/users`,
  feedbacksAnalytics: `${url}/Analytics/feedbacks`,
  feedbacksUsersAnalytics: `${url}/Analytics/feedbacks/user`,
  feedbacksUsersByDateAnalytics: `${url}/Analytics/feedbacks/user/date`,
  userFeedbacks: `${url}/User/feedbacks`,
  getMatchLiveScore: (matchId) => `${url}/Match/${matchId}/live`,
};

export { endpoints };
