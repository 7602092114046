import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActions, getMatchSquads, getMatchLiveScore } from '../../services/matches';

import ArticleMatch from './ArticleMatch';

const mapStateToProps = (state) => {
  const { user, access } = state;
  return {
    user,
    access,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getActions: bindActionCreators(getActions, dispatch),
    getMatchSquads: bindActionCreators(getMatchSquads, dispatch),
    getMatchLiveScore: bindActionCreators(getMatchLiveScore, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArticleMatch);
