import React from 'react';
import './loader.css';

const LoadingScreen = () => (
  <div className="loader">
    ...loading
  </div>
);

export default LoadingScreen;
