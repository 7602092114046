import React from 'react';
import PropTypes from 'prop-types';

const Layout = ({ children }) => (
  <div className="livematch">
    <main className="layout-middle" aria-live="polite">
      {children}
    </main>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
